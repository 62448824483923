import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class ResponseService {
    static successMessage(msg?: string){
        toast.success(msg);
    }

    static failMessage(msg?: string){
        toast.error(msg);
    }
}