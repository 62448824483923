import React, { useState, useEffect, useContext } from "react";

import {
  BaseRes,
  Category,
  EventBetTypesRes,
  EventStatusRes,
  LiabilityRes,
  Market,
  MarketStatusRes,
  ProfitLossRes,
  TenantRes,
  Tournament,
} from "../types";
import EventNavComponent from "../components/EventNavComponent";
import { Event } from "../types";
import {
  EventBetTypesRequest,
  GetTenant,
  LiabilityReq,
  PostLiability,
  ProfitLossRequest,
  eventRequest,
  marketRequest,
} from "../Request";
import { EventService } from "../services/EventService";
import { WagerService } from "../services/WagerService";
import MenuIcon from "@mui/icons-material/Menu";
import { ControlService } from "../services/ControlService";
import { ResponseService } from "../services/ResponseService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { report } from "process";

interface updateEventRequest {
  cat: Category | null | undefined;
  tourn: Tournament | null | undefined;
  eid: number | null | undefined;
}



const FieldsheetPage: React.FC = () => {
  const token = localStorage.getItem("token");
  const tenantId = localStorage.getItem("tenantID");

  const [betTypes, setBetTypes] = useState<EventBetTypesRes[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const navWidth = isNavVisible ? 256 : 0;
  const [racingData, setRacingData] = useState<Partial<Event> | undefined>();
  const [categories, setCategories] = useState<Category>();
  const [tournaments, setTournament] = useState<Tournament>();
  const [mainStatus, setMainStatus] = useState("0");
  const [initialLoad, setInitialLoad] = useState(true);
  const [expandedBetTypes, setExpandedBetTypes] = useState<{
    [betTypeId: string]: boolean;
  }>({});
  const [eventStatus, setEventStatus] = useState<EventStatusRes[]>();
  const [marketStatus, setMarketStatus] = useState<MarketStatusRes[]>();
  const [maxDescriptionWidth, setMaxDescriptionWidth] = useState<number>(0);
  const [tenantData, setTenantData] = useState<TenantRes[]>();
  const [tenantReq, setTenantReq] = useState<GetTenant>({ tenantId: null });

  // ProfitLoss Variables
  const [profitLossReq, setProfitLossReq] = useState<ProfitLossRequest>({
    betTypeId: null,
    betTypeSpecificId: null,
    dataProviderId: 1,
    eventId: null,
    marketNumber: null,
    tenantId: 1,
  });
  const [eventTypePL, setEventTypePL] = useState<ProfitLossRes>();
  const [betTypePL, setBetTypePL] = useState<ProfitLossRes[]>();
  const [marketTypePL, setMarketTypePL] = useState<ProfitLossRes[]>();

  // Update Status Variables
  const [updateEvent, setUpdateEvent] = useState<Partial<Event>>();
  const [updateBetTypes, setUpdateBetTypes] = useState<EventBetTypesRes[]>();
  const [updateMarkets, setUpdateMarkets] = useState<Market[]>();
  const [updateEventData, setUpdateEventData] = useState<updateEventRequest>({
    eid: 0,
    tourn: null,
    cat: null,
  });

  //state for updatating liabilities of bet types
  const [postBetTypeLiabilities, setPostBetTypeLiabilities] = useState<PostLiability[]>([]);
  //state for updating liability of event (case 4)
  const [postEventLiability, setPostEventLiability] = useState<PostLiability>();
  //used to receieve event liabilities
  const [eventLiabilities, setEventLiabilities] = useState<LiabilityRes[]>([]);

  interface LiabilityDictionary { [key: string]: PostLiability }

  const [liabilityDictionary, setLiabilityDictionary] = useState<LiabilityDictionary>({});
  let btpl:ProfitLossRes|undefined;

  //MARK:Populate Tables

  useEffect(() => {
    const fetchData = async () => {
      if (racingData?.eventId) {
      
          const updatedProfitLossReq = {
            ...profitLossReq,
            tenantId: 1,
            dataProviderId: 1,
            eventId: racingData.eventId,
          };
          const eventPLRes = await WagerService.ProfitLoss<ProfitLossRes[]>(
            updatedProfitLossReq,
            token
          );
         
          setProfitLossReq(updatedProfitLossReq)
          setEventTypePL(eventPLRes[0]);
          populateTables(racingData.eventId);
      }

    };

    fetchData();
    // Add dependencies
  }, [racingData?.eventId, profitLossReq.eventId]);  

  // Tailwind CSS
  const table_td = "border border-blue-400 py-1 px-3 text-center text-sm";
  const table_th =
    "border border-blue-400 py-1 px-3 w-1/10 text-center text-md";
  const inputs =
    "border border-blue-300 rounded-md w-8/10 focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1";
  const buttons =
    "border border-blue-500 rounded-md w-full bg-blue-500 hover:bg-blue-700 text-white text-center text-sm py-1 cursor-pointer";
  const dropdown =
    "border border-blue-300 rounded-md w-8/10 focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-[0.8vh] cursor-pointer";

  /**
 * Builds a dictionary of liabilities for an event, to be used in posting liabilities.
 *
 * @param {number} eventId - The ID of the event.
 * @param {LiabilityRes[] | undefined} eventLiabilitiesResponse - The response containing the event liabilities.
 * @param {EventBetTypesRes[]} betTypesResponse - The response containing the event bet types.
 * @return {void} This function does not return anything.
 */
  function buildLiabilityDictionary(eventId: number, eventLiabilitiesResponse: LiabilityRes[] | undefined, betTypesResponse: EventBetTypesRes[]) {
    const liabilityDictionary: LiabilityDictionary = {};

    //if the main event type has no liability
    const mainEventLiability = eventLiabilitiesResponse?.find((liability) =>
      liability.betTypeSpecificId === 0 &&
      liability.betTypeId === 0
    )
    if (!mainEventLiability) {
      //create a liability for the main event
      const newLiability: PostLiability = {
        eventId: eventId,
        betTypeId: 0,
        betTypeSpecificId: 0,
        liabilityAmount: 0,
        dataProviderId: 1,
        tenantId: tenantId ? parseInt(tenantId) : 1
      }
      //key -> betTypeId-betTypeSpecificId-eventId
      liabilityDictionary[`${newLiability.betTypeId}-${newLiability.betTypeSpecificId}-${newLiability.eventId}`] = newLiability
    } else {
      const newLiability: PostLiability = { ...mainEventLiability }
      liabilityDictionary[`${newLiability.betTypeId}-${newLiability.betTypeSpecificId}-${newLiability.eventId}`] = newLiability
    }

    //for each bet type, add to the dictionary
    betTypesResponse.forEach((betType) => {
      const btId = betType.betTypeId;
      const btsId = betType.betTypeSpecificId;

      const betTypeLiability = eventLiabilitiesResponse?.find((liability) =>
        liability.betTypeSpecificId === btsId &&
        liability.betTypeId === btId
      );

      if (!betTypeLiability) {
        const newLiability: PostLiability = {
          eventId: eventId,
          betTypeId: btId,
          betTypeSpecificId: btsId,
          liabilityAmount: 0,
          dataProviderId: 1,
          tenantId: tenantId ? parseInt(tenantId) : 1
        }

        liabilityDictionary[`${newLiability.betTypeId}-${newLiability.betTypeSpecificId}-${newLiability.eventId}`] = newLiability
      } else {
        const newLiability: PostLiability = { ...betTypeLiability }
        liabilityDictionary[`${newLiability.betTypeId}-${newLiability.betTypeSpecificId}-${newLiability.eventId}`] = newLiability
      }
    })

    console.log("DICTIONARY");
    console.table(liabilityDictionary);
    setLiabilityDictionary(liabilityDictionary);
  }

  const populateTables = async (eventId: number) => {
    try {
      const tResponse = await ControlService.GetTenant<TenantRes[]>(tenantReq, token);
      setTenantData(tResponse);
  
      const btRequest: EventBetTypesRequest = {
        eventId: eventId,
        betTypeId: null,
        betTypeSpecificId: null,
        dataProviderId: null,
        eventGroupId: null,
      };
  
      const mRequest: marketRequest = {
        dataProviderId: 1,
        betTypeId: null,
        betTypeSpecificId: null,
        eventId: eventId,
        marketNumber: null,
      };
  
      const btResponse = await EventService.EventBetTypes<EventBetTypesRes[]>(btRequest, token);
      setBetTypes(btResponse);
      maxWidth(btResponse);
  
      const mResponse = await EventService.GetMarket<Market[]>(mRequest, token);
      setMarkets(mResponse);
  
      const eventStatusResponse = await EventService.EventStatus<EventStatusRes[]>(token);
      setEventStatus(eventStatusResponse);
  
      const marketStatusResponse = await EventService.MarketStatus<MarketStatusRes[]>(token);
      setMarketStatus(marketStatusResponse);
  
      const eventLiabilitiesRequestParams: LiabilityReq = {
        eventId: eventId,
        dataProviderId: 1,
        tenantId: tenantId ? parseInt(tenantId) : 0,
      };
  
      const eventLiabilitiesResponse = await WagerService.GetLiabilities<LiabilityRes[]>(eventLiabilitiesRequestParams, token);
  
      buildLiabilityDictionary(eventId, eventLiabilitiesResponse, btResponse);
  
      setPostBetTypeLiabilities([]);
      setPostEventLiability(undefined);
  
      if (btResponse) {
        const betTypePLPromises = btResponse.map(async (betType) => {
          const betTypePLRes = await BetTypePL(betType.betTypeId, betType.betTypeSpecificId);
          
          return betTypePLRes;
        });
        const betTypePLResponses = await Promise.all(betTypePLPromises);
        setBetTypePL(betTypePLResponses.flat(1));
  
        const allMarketTypePLResponses: ProfitLossRes[] = [];
  
        for (const betType of btResponse) {
          const marketsForBetType = mResponse.filter(
            (market) => market.betTypeId === betType.betTypeId && market.betTypeSpecificId === betType.betTypeSpecificId
          );
          const marketPLPromises = marketsForBetType.map(async (market) => {
            const marketPLRes = await MarketPL(
              betType.betTypeId,
              betType.betTypeSpecificId,
              market.marketNumber
            );
            return marketPLRes;
          });
          const marketPLResponses = await Promise.all(marketPLPromises);
          allMarketTypePLResponses.push(...marketPLResponses);
        }
  
        setMarketTypePL(allMarketTypePLResponses.flat(1));
      }
    } catch (error) {
      console.error('Error in populateTables:', error);
    }
  };

  //Handle input changes

  const handleEventChange = async (
    event: Event,
    category: Category,
    tournament: Tournament
  ) => {
    setRacingData(event);
    setCategories(category);
    setTournament(tournament);

    
  };

  const updateInitialLoad = (iLoad: boolean) => {
    setInitialLoad(iLoad);
  };

  const toggleBetTypeVisibility = (betTypeId: string) => {
    setExpandedBetTypes((prev) => ({
      ...prev,
      [betTypeId]: !prev[betTypeId],
    }));
  };

  const maxWidth = (res: EventBetTypesRes[]) => {
    const maxWidth = res.reduce((max, type) => {
      const width =
        type.description.length < 10 ? 10 : type.description.length + 2;
      return width > max ? width : max;
    }, 0);
    setMaxDescriptionWidth(maxWidth);
  };

  const BetTypePL = async (id: number, btsId: number) => {
    const updatedReq = { ...profitLossReq, betTypeId: id, betTypeSpecificId: btsId}; // Create a new object
    return await WagerService.ProfitLoss<ProfitLossRes>(updatedReq, token);
  };

  const MarketPL = async (btid: number, btsId: number,mid: number) => {
    const updatedReq = {
      ...profitLossReq,
      betTypeId: btid,
      betTypeSpecificId: btsId,
      marketNumber: mid,
    }; // Create a new object
    return await WagerService.ProfitLoss<ProfitLossRes>(updatedReq, token);
  };

  const openHorseReport = (horse: Market, btid: number) => {
    const reportURL = process.env.REACT_APP_REPORT_URL;
    window.open(
      `${reportURL}__report=BetViewer.rptdesign&TenantId=1&EventId=${racingData?.eventId}&BetType=${btid}&MarketNumber=${horse.marketNumber}&outputFormat=html&__format=html&__pageoverflow=0&__asattachment=false&__overwrite=false`,
      "_blank"
    );
  };


  //MARK: UpdateFieldSheetData
  const updateFieldsheetData = (
    type: number,
    statusID?: number,
    bt?: EventBetTypesRes,
    mkt?: Market,
    liability?: string,
    event?: Partial<Event>
  ) => {
    if (racingData) {
      switch (type) {
        case 0:
          console.log("Case 0 Hit");
          const req: Partial<Event> = {
            ...racingData,
            eventStatusId: statusID,
          };
          setUpdateEvent(req);
          break;

        case 1:
          console.log("Case 1 Hit");
          if (bt) {
            const currBetTypes: EventBetTypesRes[] = betTypes
              ? [...betTypes]
              : [];
            let check = false;

            currBetTypes.map((betType) => {
              if (
                betType.betTypeId === bt.betTypeId &&
                betType.betTypeSpecificId === bt.betTypeSpecificId &&
                statusID
              ) {
                betType.eventStatusId = statusID;
                check = true;
              }
            });

            if (!check && statusID) {
              const newBetType: EventBetTypesRes = {
                ...bt,
                eventStatusId: statusID,
              };

              currBetTypes.push(newBetType);
            }

            setUpdateBetTypes(currBetTypes);
          }

          break;

        case 2:
          console.log("Case 2 Hit");
          if (mkt) {
            const currMarkets: Market[] = updateMarkets
              ? [...updateMarkets]
              : [];
            let check = false;

            currMarkets.map((market) => {
              if (market.marketNumber === mkt.marketNumber && statusID) {
                market.marketStatusId = statusID;
                check = true;
              }
            });

            if (!check && statusID) {
              const newMarket: Market = {
                ...mkt,
                marketStatusId: statusID,
              };

              currMarkets.push(newMarket);
            }

            setUpdateMarkets(currMarkets);
          }
          break;

        case 3:
          console.log("Case 3 Hit");
          if (statusID && statusID !== 0) {
            setUpdateBetTypes(betTypes);

            updateBetTypes?.map((betType) => {
              betType.eventStatusId = statusID;
            });
          }

          setMainStatus(statusID ? statusID.toString() : "0");
          break;

        case 4:
          //Liability at the top of page
          //UPDATES EVENT LIABILITY
          console.log("Case 4 Hit");
          if (liability && tenantId && racingData.eventId) {
            const postLiability: PostLiability = {
              eventId: racingData.eventId,
              betTypeId: 0,
              betTypeSpecificId: 0,
              tenantId: Number(tenantId),
              dataProviderId: 1,
              liabilityAmount: parseInt(liability),
            }
            setPostEventLiability(postLiability);
          }
          break;

        case 5:
          //Liability on bet individual type
          if (bt && liability && tenantId) {
            console.log("Case 5 Hit");
            let postLiabilities: PostLiability[] = [];
            const currBetTypes: EventBetTypesRes[] = betTypes
              ? [...betTypes]
              : [];

            //build the request obj to post liabilities
            postLiabilities = [...currBetTypes.map((betType) => {
              if (
                betType.betTypeId === bt.betTypeId &&
                betType.betTypeSpecificId === bt.betTypeSpecificId &&
                liability
              ) {
                return {
                  tenantId: Number(tenantId),
                  dataProviderId: 1,
                  betTypeId: betType.betTypeId,
                  betTypeSpecificId: betType.betTypeSpecificId,
                  eventId: betType.eventId,
                  liabilityAmount: parseInt(liability),
                }
              } else {
                return {
                  tenantId: Number(tenantId),
                  dataProviderId: 1,
                  betTypeId: betType.betTypeId,
                  betTypeSpecificId: betType.betTypeSpecificId,
                  eventId: betType.eventId,
                  liabilityAmount: betType.liability ? betType.liability : 0,
                }
              }
            })]

            console.table(postLiabilities);
            setPostBetTypeLiabilities(postLiabilities);
            setUpdateBetTypes(currBetTypes);
          }
          break;

        default:
          break;
      }
    }
  };

  //MARK: Update FieldSheet itself
  const updateFieldSheet = async () => {
    try {
      if (updateEvent && updateEvent !== null) {
        const eventResponse = await EventService.UpdateEvent<BaseRes>(
          updateEvent,
          token
        );
      }

      console.table(Object.values(liabilityDictionary));

      const liabilityPromises = Object.values(liabilityDictionary).map((liability) =>
        WagerService.PostLiability<BaseRes>(liability, token)
      );

      const liabilityResponses = await Promise.all(liabilityPromises);
      liabilityResponses.forEach((res) => console.log(res));


      if (updateMarkets && updateMarkets.length > 0) {
        const marketPromises = updateMarkets.map((market) =>
          EventService.UpdateMarket<BaseRes>(market, token)
        );
        const marketResponses = await Promise.all(marketPromises);
        marketResponses.forEach((res) => console.log(res));
      }

      if (racingData) {
        setUpdateEventData({
          ...updateEventData,
          eid: racingData.eventId,
          tourn: tournaments,
          cat: categories,
        });
      }

      setUpdateBetTypes([]);
      setUpdateEvent({ ...racingData });
      setUpdateMarkets([]);
    } catch (error) {
      console.log(error);
    }
  };

  const updateEventIDFromChild = (data: updateEventRequest) => {
    setUpdateEventData({
      eid: data.eid,
      tourn: data.tourn,
      cat: data.cat,
    });
  };
  //let btpl:ProfitLossRes|undefined;


  

  //MARK: JSX RETURN
  return (
    <>
      <div>
        <div>
          <div>
            <button
              onClick={() => setIsNavVisible(!isNavVisible)}
              className="fixed top-2 left-1 z-50 bg-blue-400 hover:bg-blue-500 text-white font-bold text-center rounded flex items-center justify-center"
              style={{ minWidth: "48px", minHeight: "48px" }}
            >
              <MenuIcon />
            </button>

            {/* MARK: EVENTNAV */}
            {isNavVisible && (
              <div
                className={`text-left p-4 shadow-2xl h-screen lg:w-1/6 w-1/4 fixed bg-white overflow-y-auto max-h-[calc(100vh-4rem)] font-inter border-b-2 w-${navWidth}`}
              >
                <EventNavComponent
                  onEventChange={handleEventChange}
                  initialLoad={initialLoad}
                  onUpdateInitialLoad={updateInitialLoad}
                  handleUpdateEvent={updateEventData}
                  updateEventIDFromChild={updateEventIDFromChild}
                />
              </div>
            )}
          </div>

          <div //FULL PAGE
            style={{ paddingLeft: `${navWidth}px` }}
            className="transition-all duration-300 lg:flex flex-col flex-wrap"
          >
            {/* MARK: EVENT INFO */}
            <div className="container mx-auto p-4 w-full">
              <div className="flex flex-row">
                <div className="w-full">
                  <table className="w-full">
                    <tbody>
                      {/* HEADINGS */}
                      <tr className="border border-blue-400 bg-blue-100 ">
                        {/* TENANT */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Tenant: </span>
                          {tenantData && tenantId && (
                            <span>
                              {
                                tenantData.filter(
                                  (x) => x.tenantId === parseInt(tenantId)
                                )[0].tenantShortName
                              }
                            </span>
                          )}
                        </td>

                        {/* LOCATION */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Location: </span>
                          {tournaments && (
                            <span>
                              {tournaments?.tournamentName},{" "}
                              {categories?.categoryName}
                            </span>
                          )}
                        </td>

                        {/* EVENT NAME */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Name: </span>
                          {racingData && <span>{racingData.eventName}</span>}
                        </td>

                        {/* EVENT DATE */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Date: </span>
                          {racingData && <span>{racingData.eventDate}</span>}
                        </td>
                      </tr>


                      <tr className="bg-white">
                        {/* EVENT ID */}
                        <td className="px-4 py-3">
                          <span className="font-bold">ID: </span>
                          <span>{racingData?.eventId}</span>
                        </td>

                        {/* LIABILITY */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Liability: </span>
                          <span>
                            <input
                              type="number"
                              id="max"
                              //value={eventLiabilities.find((liability) => liability.betTypeSpecificId === 0)?.liabilityAmount?.toString() || ""}
                              value={liabilityDictionary[`0-0-${racingData?.eventId}`]?.liabilityAmount?.toString() || ""}
                              onChange={(e) => {
                                const key = `0-0-${racingData?.eventId}`;
                                const newValue = parseFloat(e.target.value);
                                setLiabilityDictionary(prevState => ({
                                  ...prevState,
                                  [key]: {
                                    ...prevState[key],
                                    liabilityAmount: isNaN(newValue) ? null : newValue
                                  }
                                }));
                              }
                              }
                              className={`${inputs}`}
                            />
                          </span>
                        </td>

                        {/* EVENT STATUS */}
                        <td className="px-4 py-3">
                          <span className="font-bold">Event Status: </span>
                          <select
                            id="eid"
                            className={`${dropdown}`}
                            onChange={(e) =>
                              updateFieldsheetData(
                                0,
                                parseInt(e.target.value),
                                undefined,
                                undefined,
                                undefined,
                                racingData ? racingData : undefined
                              )
                            }
                          >
                            {racingData &&
                              eventStatus &&
                              eventStatus.map((e) => (
                                <option
                                  key={"event-" + e.eventStatusId}
                                  value={e.eventStatusId}
                                  selected={
                                    e.eventStatusId === racingData.eventStatusId
                                  }
                                >
                                  {e.eventStatusName}
                                </option>
                              ))}
                          </select>
                        </td>

                        {/* SAVE BUTTON */}
                        <td className="px-4 py-3">
                          <button
                            className={`btn primary w-full py-1`}
                            onClick={updateFieldSheet}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr className="mt-2 border-t-2 border-blue-300" />

              {/* MARK: PROFIT/LOSS/BET TYPE STATUS */}
              <div className="mt-5 justify-end flex">
                {/* MARK: TOTAL PROFIT/LOSS */}
                <label htmlFor="totalProfitLoss" className="mr-2 pt-2">Total Profit/Loss: </label>
                <input
                  name="totalProfitLoss"
                  type="text"
                  id="max"
                  className="border rounded mx-2 px-2 py-1 text-center"
                  value={eventTypePL?.totalProfitLoss}
                  disabled
                />

                {/* MARK: BET TYPE STATUS */}
                <label className="mr-2 pt-2">Bet Type Status (All): </label>
                <select
                  className="border border-gray-300 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-500 pr-5"
                  value={mainStatus}
                  onChange={(e) =>
                    updateFieldsheetData(3, parseInt(e.target.value))
                  }
                >
                  <option selected={true} value={0}>
                    Select Status
                  </option>
                  {eventStatus &&
                    eventStatus.map((e) => (
                      <option
                        key={"evStatus-" + e.eventStatusId}
                        value={e.eventStatusId}
                      >
                        {e.eventStatusName}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* MARK: BET TYPE TABLES  */}
            <div className="container mx-auto p-4 w-full" >
              {betTypes && betTypes.length > 0 ? (
                betTypes.map((type, btIndex) => {
                  const typeMarkets = markets.filter(
                    (m) => m.betTypeId === type.betTypeId
                  );

                   btpl = betTypePL?.filter(
                    (x) => x.betTypeId === type.betTypeId && x.betTypeSpecificId === type.betTypeSpecificId
                  )[0];

                  return (
                    <div key={`${type.betTypeId}-${btIndex}`} className="mb-4">
                      <div className="overflow-x-auto">
                        {/* BET TYPE TABLES */}
                        <table className="min-w-full">
                          <thead>
                            <tr>
                              {/* BET TYPE */}
                              <th
                                colSpan={2}
                                className="border border-blue-400 bg-blue-100"
                              >
                                <span className="flex justify-start items-center">
                                  {typeMarkets.length > 0 ? (
                                    <span
                                      className="cursor-pointer mx-4"
                                      onClick={() =>
                                        toggleBetTypeVisibility(
                                          `${type.betTypeId}-${btIndex}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {expandedBetTypes[
                                        `${type.betTypeId}-${btIndex}`
                                      ]
                                        ? "▲"
                                        : "▼"}
                                    </span>
                                  ) : (
                                    <span
                                      className="cursor-pointer mx-4"
                                      style={{ visibility: "hidden" }}
                                    >
                                      ▲
                                    </span>
                                  )}
                                  <span
                                    className="text-left text-nowrap mr-4 "
                                    style={{
                                      width: `${maxDescriptionWidth * 12}px`,
                                    }}
                                  >
                                    Bet Type: {type.description}
                                  </span>
                                </span>
                              </th>

                              {/* LIABILITY */}
                              <th
                                colSpan={3}
                                className="border border-blue-400 bg-blue-100 w-3/10"
                              >
                                <span className="flex justify-end w-full pr-5">
                                  <label className="ml-14 mr-2 pt-1">
                                    Liability: 
                                  </label>
                                  <input
                                    type="number"
                                    id="max"
                                    className="input px-2"
                                    onChange={(e) =>
                                      {
                                        const key = `${type.betTypeId}-${type.betTypeSpecificId}-${type.eventId}`;
                                        const newValue = parseFloat(e.target.value);
                                        setLiabilityDictionary(prevState => ({
                                          ...prevState,
                                          [key]: {
                                            ...prevState[key],
                                            liabilityAmount: isNaN(newValue) ? null : newValue
                                          }
                                        }));
                                      }
                                    }
                                    value={liabilityDictionary[`${type.betTypeId}-${type.betTypeSpecificId}-${type.eventId}`]?.liabilityAmount?.toString() || ""}
                                  />
                                </span>
                              </th>

                              {/* PROFIT/LOSS */}
                              <th
                                colSpan={3}
                                className="border border-blue-400 bg-blue-100  w-3/10"
                              >
                                <div className="w-full flex justify-end pr-5">
                                  <label className="mr-2 pt-1">
                                    Profit/Loss:
                                  </label>
                                  <input
                                    type="text"
                                    id="max"
                                    className="border rounded px-2 py-1 text-center"
                                    disabled
                                    value={btpl?.totalProfitLoss}
                                  />
                                </div>
                              </th>

                              {/* BET TYPE STATUS */}
                              <th
                                className="border border-blue-400 bg-blue-100 w-1/10"
                                colSpan={1}
                              >
                                <select
                                  className="border border-gray-300 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  onChange={(e) =>
                                    updateFieldsheetData(
                                      1,
                                      parseInt(e.target.value),
                                      type
                                    )
                                  }
                                  disabled={mainStatus !== "0"}
                                >
                                  {eventStatus &&
                                    eventStatus.map((e) => (
                                      <option
                                        key={"btStatus-" + e.eventStatusId}
                                        value={e.eventStatusId}
                                        selected={
                                          e.eventStatusId === type.eventStatusId
                                        }
                                      >
                                        {e.eventStatusName}
                                      </option>
                                    ))}
                                </select>
                              </th>
                            </tr>
                            {/* MARK: TABLE BODY */}
                            {expandedBetTypes[
                              `${type.betTypeId}-${btIndex}`
                            ] && (
                                /* TABLE HEADINGS */
                                <tr>
                                  <th className={`${table_th}`}>No</th>
                                  <th className={`${table_th}`}>Pos</th>
                                  <th className={`${table_th} w-3/10`}>Name</th>
                                  <th className={`${table_th}`}>Prev</th>
                                  <th className={`${table_th}`}>Odd</th>
                                  <th className={`${table_th}`}>Avp</th>
                                  <th className={`${table_th}`}>Stake</th>
                                  <th className={`${table_th}`}>Profit/Loss</th>
                                  <th className={`${table_th}`}>Status</th>
                                </tr>
                              )}
                          </thead>
                          {expandedBetTypes[`${type.betTypeId}-${btIndex}`] &&
                            typeMarkets.length > 0 && (
                              <tbody>
                                {/* TABLE DATA */}
                                {typeMarkets.map((horse, index) => {
                                  const mpl = marketTypePL?.filter(
                                    (x) => x.marketNumber === horse.marketNumber && x.betTypeId === horse.betTypeId
                                  )[0];

                                  return (
                                    <tr key={index}>
                                      <td className={`${table_td}`}>
                                        {index + 1}
                                      </td>
                                      <td className={`${table_td}`}></td>
                                      {/* Position */}
                                      <td
                                        className={`${table_td} cursor-pointer hover:text-blue-400 hover:underline`}
                                        onClick={() => openHorseReport(horse, type.betTypeSpecificId)}
                                      >
                                        {horse.marketName}
                                      </td>
                                      <td className={`${table_td}`}>
                                        {horse.previousOdd}
                                      </td>
                                      <td className={`${table_td}`}>
                                        {horse.currentOdd}
                                      </td>
                                      <td className={`${table_td}`}>1</td>
                                      {/* Avp */}
                                      <td className={`${table_td}`}>
                                        {mpl?.totalStake}
                                      </td>
                                      {/* Stake */}
                                      <td className={`${table_td}`}>
                                        {mpl?.totalProfitLoss}
                                      </td>
                                      {/* Profit/Loss */}
                                      <td className={`${table_td}`}>
                                        <select
                                          className="border border-gray-300 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                                          onChange={(e) =>
                                            updateFieldsheetData(
                                              2,
                                              parseInt(e.target.value),
                                              undefined,
                                              horse
                                            )
                                          }
                                        >
                                          {marketStatus &&
                                            marketStatus.map((s) => (
                                              <option
                                                value={s.marketStatusId}
                                                key={
                                                  "mStatus=" + s.marketStatusId
                                                }
                                                selected={
                                                  s.marketStatusId ===
                                                  horse.marketStatusId
                                                }
                                              >
                                                {s.marketStatusName}
                                              </option>
                                            ))}
                                        </select>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                        </table>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No bet types</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer></ToastContainer>
    </>
  );
};

export default FieldsheetPage;
