import React, { useState, useEffect } from 'react';
import { ReportListRes } from "../types";
import { AdminApiService } from "../services/AdminApiService";
import ReportNavComponent from '../components/ReportNavComponent';
import MenuIcon from "@mui/icons-material/Menu";

const ReportPage: React.FC = () => {
  const token = localStorage.getItem('token');
  const [isNavVisible, setIsNavVisible] = useState(true);
  const navWidth = isNavVisible ? 20 : 0;
  const mainWidth = isNavVisible ? 80 : 100;
  const [reportList, setReportList] = useState<ReportListRes[]>();
  const [selectedReport, setSelectedReport] = useState<ReportListRes>({
    reportName: '',
    reportUrl: ''
  });

  useEffect(() => {
    getReportList();
  }, []); 

  const getReportList = async () => {
    try {
      const res = await AdminApiService.ReportList<ReportListRes[]>(token);
      setReportList(res);
    } catch (error) {
      setReportList([]);
    }
  }

  const handleClick = (report: ReportListRes) => {
    setSelectedReport(report);
  }

  return (
    <div className='flex w-full h-screen'>
      <div style={{width: `${navWidth}%`}}>
        <button
            onClick={() => setIsNavVisible(!isNavVisible)}
            className="fixed top-2 left-1 z-50 bg-blue-400 hover:bg-blue-500 text-white font-bold text-center rounded flex items-center justify-center"
            style={{ minWidth: "48px", minHeight: "48px" }}
          >
            <MenuIcon />
          </button>

        {isNavVisible && reportList && (
          <div className='text-left p-4 shadow-2xl lg:h-screen lg:w-1/5 w-1/5 fixed bg-white overflow-y-auto max-h-[90vh] font-inter border-b-2'>
            <ReportNavComponent
              ReportListProp={reportList}
              onReportClick={handleClick}
              SelectedReport={selectedReport}
            />
          </div>
        )}
      </div>

      <div style={{width: `${mainWidth}%`}}>
        <iframe src={selectedReport?.reportUrl} className="w-full h-full"></iframe>
      </div>
    </div>
  );
};

export default ReportPage;
