import React, { useState, useEffect, useRef } from "react";
import {
  Location,
  TenantRes,
  CreateTenantRes,
  TenantSettings,
  TenantSettingsTypes,
  AddTenantSettingsRes,
  RegionRes,
  BaseRes,
  CountryRes,
} from "../types";
import {
  getLocation,
  GetTenant,
  CreateTenant,
  GetTenantSettings,
  createLocationReq,
} from "../Request";
import { ResponseService } from "../services/ResponseService";
import { ControlService } from "../services/ControlService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/Modal";

const TenantPage: React.FC = () => {
  const token = localStorage.getItem('token');
  //Loading Variable
  const [loading, setLoading] = useState(true);

  //Tenant Variable
  const [tenantData, setTenantData] = useState<TenantRes[]>();
  const [tenantReq, setTenantReq] = useState<GetTenant>({ tenantId: null });
  const [createTenantReq, setCreateTenantReq] = useState<CreateTenant>({
    locationId: 0,
    tenantId: 0,
    tenantDescription: "",
    tenantShortName: "",
  });
  const [selectedTenant, setSelectedTenant] = useState<TenantRes>();

  //Tenant Toggle - Create, Filter
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);

  const [toggleCreateLocation, setToggleCreateLocation] = useState(false);
  const [toggleFilterLocation, setToggleFilterLocation] = useState(false);

  const [toggleCreateSetting, setToggleCreateSetting] = useState(false);
  const [toggleFilterSetting, setToggleFilterSetting] = useState(false);

  // Tenant Settings Variables
  const [tenantSettingsReq, setTenantSettingsReq] = useState<GetTenantSettings>(
    { regionId: null, settingId: null, tenantId: null }
  );
  const [tenantSettings, setTenantSettings] = useState<TenantSettings[]>();
  const [filteredSettings, setFilteredSettings] = useState<TenantSettings[]>();
  const [settingTypes, setSettingsTypes] = useState<TenantSettingsTypes[]>();
  const [postSetting, setPostSetting] = useState<TenantSettings>({
    regionId: 0,
    settingId: 0,
    settingNumericValue: 0,
    settingStringValue: "",
    settingTypeId: 0,
    tenantId: 0,
  });
  const [operatorSettings, setOperatorSettings] = useState({
    id: 0,
    display: false,
  });

  const [editOpSettings, setEditOpSettings] = useState(false);
  const [editSelectedSetting, setEditSelectedSettings] =
    useState<TenantSettings>();

  // Tenant Location Variables
  const [locationRequest, setLocationRequest] = useState<getLocation>({
    locationId: null,
    regionId: null,
    tenantId: null,
  });
  const [locations, setLocations] = useState<Location[]>();
  const [filteredLocations, setFilteredLocations] = useState<Location[]>();
  const [defaultLocation, setDefaultLocation] = useState<Location[]>();

  const [editTenantLocation, setEditTenantLocation] = useState(false);
  const [editSelectedLocation, setEditSelectedLocation] = useState<Location>();

  const [createLocationReq, setCreateLocationReq] = useState<createLocationReq>(
    {
      locationId: 0,
      tenantId: 0,
      regionId: 0,
      countryId: 0,
      locationName: "",
      locationDescription: "",
      email1: "",
      email2: "",
      phone1: "",
      phone2: "",
      addressLine1: "",
      addressLine2: "",
    }
  );

  //Region Variable
  const [regions, setRegions] = useState<RegionRes[]>();
  const [countries, setCountries] = useState<CountryRes[]>();

  //Modal Variables
  const [showModal, setShowModal] = useState(false);
  const [DetailsDisplay, setDetailsDisplay] = useState(true);
  const [SettingsDisplay, setSettingsDisplay] = useState(false);
  const [LocationDisplay, setLocationDisplay] = useState(false);

  //Style Variables
  const inputs =
    "border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1 invalid:border-red-500 invalid:bg-red-100";
  const buttons =
    "border border-blue-500 rounded-md w-full bg-blue-500 hover:bg-blue-700 text-white text-center text-sm py-1";
  const dropdown =
    "border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-[0.8vh] cursor-pointer invalid:border-red-500 invalid:bg-red-100";
  const filter =
    "w-full text-left text-md px-4 py-2 flex justify-between items-center focus:outline-none";
  const filter_container =
    "flex flex-wrap align-center justify-start bg-blue-100 focus:outline-none";
  const filter_content =
    "w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1 focus:outline-none";

  const modal_filter =
    "w-full text-left text-md px-2 py-1 flex justify-between items-center focus:outline-none";
  const modal_filter_container =
    "flex flex-wrap align-center justify-start focus:outline-none";
  const modal_filter_content =
    "w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1 focus:outline-none";

  const table = "";
  const table_tr = "";
  const table_th = "border py-1 text-center text-md";
  const table_td = "border py-1 text-center text-sm";
  const table_inputs =
    "border border-blue-300 rounded-md w-3/4 focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm";
  const table_buttons =
    "border border-blue-400 rounded-md w-4/10 focus:outline-none hover:ring-2 hover:ring-blue-500 hover:bg-blue-400 hover:text-white text-center text-sm w-1/2";
  const table_dropdown =
    "border border-blue-300 rounded-md w-3/4 focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-[0.3vh] cursor-pointer";

  useEffect(() => {
    getAllTenants();
    getDefaultLocation();
    getSettingTypes();
    getSettings();
    GetRegion();
    GetCountries();

    setLoading(false);
  }, []);

  //---------------- Tenant Data - Get, Create, Update ----------------//
  // Get All Tenants

  const [createTenantFormValid, setTenantCreateFormValid] = useState(false);

  function onCreateTenantFormChange(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    //*The way onChange works is that it returns the element that changed, even if you assign it to the form
    //in THIS CASE SPECIFICALLY we know that it's two parents up
    const form = event.target.parentElement?.parentElement as HTMLFormElement;

    console.log(form);
    console.log(createTenantFormValid)

    const validation = form.checkValidity();
    if (validation === true) {
      setTenantCreateFormValid(true);
    } else {
      setTenantCreateFormValid(false);
    }
  }

  //final validation check on the form before submission
  async function onCreateTenantFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    //get the validity of the form
    const form = event.currentTarget as HTMLFormElement;
    const valid = form.checkValidity();
    if (valid) {
      await createTenant();
    } else {
      ResponseService.failMessage("Invalid Input");
    }
  }


  const getAllTenants = async () => {
    const tResponse = await ControlService.GetTenant<TenantRes[]>(tenantReq, token);

    tResponse?.sort((a, b) => {
      const aa = a.tenantId;
      const bb = b.tenantId;
      return aa - bb;
    });

    if (tResponse) {
      setCreateTenantReq({
        ...createTenantReq,
        tenantId: tResponse.length > 0 ? tResponse[tResponse.length - 1].tenantId + 1 : 0,
      });
    }

    setTenantData(tResponse);
  };

  // Create New Tenant
  const createTenant = async () => {
    await ControlService.PostTenant<CreateTenantRes>(createTenantReq, token)
      .then(async (res) => {
        if (res.responseCode !== 200) {
          ResponseService.failMessage(res.responseMessage);
        } else {
          ResponseService.successMessage(res.responseMessage);
          await getAllTenants();
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to create tenant");
      });
  };

  //---------------------------- Tenant Modal ----------------------------//
  //Toggle Tenant Settings Modal
  const toggleModal = () => {
    setSelectedTenant(undefined);
    setDetailsDisplay(true);
    setSettingsDisplay(false);
    setLocationDisplay(false);
    setShowModal(!showModal);
  };

  //Handle Modal Display - Sub Settings
  const handleDisplay = async (val: number, id: number) => {
    switch (val) {
      case 0:
        setDetailsDisplay(true);
        setSettingsDisplay(false);
        setLocationDisplay(false);
        break;
      case 1:
        await getFilteredSettings(id).then((res) => {
          setDetailsDisplay(false);
          setSettingsDisplay(true);
          setLocationDisplay(false);
        });

        break;
      case 2:
        await getFilteredLocations(id).then((res) => {
          setDetailsDisplay(false);
          setSettingsDisplay(false);
          setLocationDisplay(true);
        });

        break;
    }
  };

  //-------------- Tenant Settings Get, Create, Update --------------//
  // Get Tenant Settings
  const getSettings = async () => {
    const res = await ControlService.TenantSettings<TenantSettings[]>(
      tenantSettingsReq, token
    );
    setTenantSettings(res);

    await ControlService.TenantSettings<TenantSettings[]>(tenantSettingsReq, token)
      .then((res) => setTenantSettings(res))
      .catch((error) =>
        ResponseService.failMessage("Failed to retrieve tenant settings")
      );
  };

  // Get Setting Types
  const getSettingTypes = async () => {
    await ControlService.TenantSettingsType<TenantSettingsTypes[]>(token)
      .then((res) => {
        setSettingsTypes(res);
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to retrieve setting types");
      });
  };

  // Setting Type Dropdown
  const handleSettingChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    t: TenantRes
  ) => {
    const stid = parseInt(e.target.value);
    let sid = 0;

    if (filteredSettings && filteredSettings.length > 0) {
      sid = filteredSettings[filteredSettings.length - 1].settingId + 1;
    }

    setPostSetting({
      ...postSetting,
      tenantId: t.tenantId,
      settingTypeId: stid,
      settingId: sid,
    });
  };

  // Create Setting
  const pushSetting = async (set: TenantRes) => {
    await ControlService.AddTenantSettings<AddTenantSettingsRes>(postSetting, token)
      .then(async (resp) => {
        if (resp.responseCode !== 200) {
          ResponseService.failMessage(resp.responseMessage);
          console.log(resp.responseMessage);
        } else {
          ResponseService.successMessage(resp.responseMessage);
          setEditOpSettings(false);
          await getFilteredSettings(postSetting.tenantId);
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to add setting");
        console.log(error);
      });
  };

  // Filter Setting by selected tenant
  const getFilteredSettings = async (id: number) => {
    let req = tenantSettingsReq;
    req.tenantId = id;

    await ControlService.TenantSettings<TenantSettings[]>(req, token)
      .then(async (response) => {
        response.sort((a, b) => {
          let aa = a.settingId;
          let bb = b.settingId;
          return aa - bb;
        });

        // Set initial value for post setting
        const sid =
          response.length > 0 ? response[response.length - 1].settingId + 1 : 0;
        const stid =
          settingTypes && settingTypes.length > 0
            ? settingTypes[0].settingTypeId
            : 0;
        const rid = regions && regions.length > 0 ? regions[0].regionId : 0

        setPostSetting({
          ...postSetting,
          tenantId: id,
          settingId: sid,
          settingTypeId: stid,
          regionId: rid
        });

        setFilteredSettings(response);
      })
      .catch((error) => console.log(error));
  };

  // Display Edit btn
  const handleEditOperatorSettings = async (fs: TenantSettings) => {
    setEditOpSettings(true);

    if (fs.regionId === 0 && regions) {
      fs.regionId = regions[0].regionId;
    }

    setEditSelectedSettings(fs);
  };

  // Update Setting
  const handleSaveEditSetting = async (set: TenantSettings) => {
    await ControlService.AddTenantSettings<BaseRes>(set, token)
      .then(async (resp) => {
        if (resp.responseCode !== 200) {
          ResponseService.failMessage(resp.responseMessage);
          console.log(resp.responseMessage);
        } else {
          ResponseService.successMessage(resp.responseMessage);
          setEditOpSettings(false);
          await getFilteredSettings(set.tenantId);
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to update setting");
        console.log(error);
      });
  };

  //-------------- Tenant Locations Create, Update --------------//


  // Get All Regions
  const GetRegion = async () => {
    await ControlService.GetRegions<RegionRes[]>(token)
      .then((res) => {
        setRegions(res);
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to retrieve regions");
        console.log("Region error", error);
      });
  };

  // Get All Countries
  const GetCountries = async () => {
    await ControlService.GetCountries<CountryRes[]>(token)
      .then((res) => {
        setCountries(res);
      })
      .catch((error) => {
        console.log("Retrieve Country Error:", error);
        ResponseService.failMessage("Failed to retrieve countries");
      });
  };

  // Get Default Location
  const getDefaultLocation = async () => {
    await ControlService.GetLocation<Location[]>(locationRequest, token)
      .then((res) => {
        setLocations(res);
        if (res.length > 0) {
          setCreateTenantReq({
            ...createTenantReq,
            locationId: res[0].locationId,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        ResponseService.failMessage("Failed to retrieve locations");
      });
  };

  // Get Filtered Tenant Locations
  const getFilteredLocations = async (id: number) => {
    let req = locationRequest;
    req.tenantId = id;

    await ControlService.GetLocation<Location[]>(req, token)
      .then((response) => {
        response.sort((a, b) => {
          let aa = a.locationId;
          let bb = b.locationId;
          return aa - bb;
        });

        // Initial Create Req
        const lid =
          response.length > 0
            ? response[response.length - 1].locationId + 1
            : 0;
        setCreateLocationReq({
          ...createLocationReq,
          tenantId: id,
          locationId: lid,
        });

        setFilteredLocations(response);
      })
      .catch((error) => {
        console.log(error);
        ResponseService.failMessage("Failed to retrieve locations");
      });
  };

  //MARK: CREATE LOCATION FORM METHODS
  const [createLocationValid, setCreateLocationValid] = useState(false);

  //final validation check on the form before submission
  async function onCreateLocationFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    //get the validity of the form
    const form = event.currentTarget as HTMLFormElement;
    const valid = form.checkValidity();
    if (valid) {
      await createTenantLocation(createLocationReq.tenantId);
    } else {
      ResponseService.failMessage("Invalid Input");
    }
  }

  //runs whenever an input in the form changes
  function onCreateLocationFormChange(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    //*The way onChange works is that it returns the element that changed, even if you assign it to the form
    //in THIS CASE SPECIFICALLY we know that it's two parents up
    const form = event.target.parentElement?.parentElement as HTMLFormElement;
    const validation = form.checkValidity();
    if (validation === true) {
      setCreateLocationValid(true);
    } else {
      setCreateLocationValid(false);
    }
  }

  // Create Tenant Location
  const createTenantLocation = async (id: number) => {
    await ControlService.CreateLocation<BaseRes>(createLocationReq, token)
      .then(async (resp) => {
        if (resp.responseCode !== 200) {
          ResponseService.failMessage(resp.responseMessage);
          console.log(resp.responseMessage);
        } else {
          ResponseService.successMessage(resp.responseMessage);
          setEditOpSettings(false);
          await getFilteredLocations(id);
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to create location");
        console.log(error);
      });
  };

  // Display Edit btn
  const handleEditTenantLocation = async (fl: Location) => {
    setEditTenantLocation(true);

    if (fl.regionId === 0 && regions) {
      fl.regionId = regions[0].regionId;
    }

    setEditSelectedLocation(fl);
  };

  // Update Location
  const updateTenantLocation = async (loc: Location) => {
    await ControlService.CreateLocation<BaseRes>(loc, token)
      .then(async (resp) => {
        if (resp.responseCode !== 200) {
          ResponseService.failMessage(resp.responseMessage);
          console.log(resp.responseMessage);
        } else {
          ResponseService.successMessage(resp.responseMessage);
          setEditTenantLocation(false);
          await getFilteredLocations(loc.tenantId);
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to update location");
        console.log(error);
      });
  };

  //MARK: EDIT TENANT
  const [editTenantFormValues, setEditTenantFormValues] = useState<TenantRes|null>();
  const [isEditTenantFormValid, setIsEditTenantFormValid] = useState(false);

  const editTenantFormRef = useRef<HTMLFormElement>(null);

  function validateEditTenantForm() {
    const form = editTenantFormRef.current as HTMLFormElement;
    const valid = form.checkValidity();
    if (valid === true) {
      return true;
    } else {
      return false;
    }
  }

  function onEditTenantFormFieldChange() {
    setIsEditTenantFormValid(validateEditTenantForm());
  }

  function onEditTenantFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const valid = validateEditTenantForm();
    if (valid === true && editTenantFormValues) {
      editTenant({...editTenantFormValues});
    } else {
      ResponseService.failMessage("Invalid Input");
    }
  }

  async function editTenant(tenant: TenantRes) {
    await ControlService.PostTenant<CreateTenantRes>(tenant, token)
      .then(async (res) => {
        if (res.responseCode !== 200) {
          ResponseService.failMessage(res.responseMessage);
        } else {
          ResponseService.successMessage("Updated Tenant");
          setIsEditTenantFormValid(false);
          setEditTenantFormValues(null);
          await getAllTenants();
        }
      })
      .catch((error) => {
        ResponseService.failMessage("Failed to update tenant");
      });
  }




  //MARK: JSX RETURN
  return (
    <div>
      <div id="CreateTenant" className="container mx-auto p-2">
        <div className="bg-blue-200">
          <button
            className={`${filter}`}
            onClick={() => setToggleCreate(!toggleCreate)}
          >
            <span className="text-lg">Create Tenant</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 transform transition-transform duration-300 ${toggleCreate ? "rotate-180" : ""
                }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>



          {toggleCreate && (
            //MARK: CREATE TENANT FORM
            <form onChange={onCreateTenantFormChange} onSubmit={onCreateTenantFormSubmit} className={`${filter_container} p-2`}>
              {/* TENANT NAME */}
              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1`}>
                <input
                  required
                  id="tenantShortName"
                  name="tenantShortName"
                  type="text"
                  placeholder="Tenant Name"
                  className={"input w-full py-1 text-sm"}
                  value={createTenantReq.tenantShortName || ''}
                  onChange={(e: any) =>
                    setCreateTenantReq({
                      ...createTenantReq,
                      tenantShortName: e.target.value,
                    })
                  }
                />
              </div>

              {/* TENANT DESCRIPTION */}
              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1`}>
                <input
                  required
                  id="tenantDescription"
                  name="tenantDescription"
                  type="text"
                  placeholder="Description"
                  className={"input w-full py-1 text-sm"}
                  value={createTenantReq.tenantDescription || ''}
                  onChange={(e: any) =>
                    setCreateTenantReq({
                      ...createTenantReq,
                      tenantDescription: e.target.value,
                    })
                  }
                />
              </div>

              {/* TENANT LOCATION */}
              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1`}>
                <select
                  required
                  form="editTenantForm"
                  id="locationId"
                  name="locationId"
                  className={"select w-full py-[0.4em] text-sm"}
                  value={createTenantReq.locationId || ''}
                  onChange={(e) =>
                    setCreateTenantReq({
                      ...createTenantReq,
                      locationId: parseInt(e.target.value),
                    })
                  }
                >
                  <option value="">Select Location</option>
                  {locations &&
                    locations.map((l) => (
                      <option key={"loc-" + l.locationId} value={l.locationId}>
                        {l.locationName}
                      </option>
                    ))}
                </select>
              </div>

              {/* SUBMIT BUTTON */}
              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1`}>
                <button
                  //onClick={createTenant} 
                  disabled={createTenantFormValid ? false : true}
                  className={"btn primary w-full py-1 text-sm"}
                  type="submit"
                >
                  Create Tenant
                </button>
              </div>
            </form>
          )}

        </div>
      </div>

      <div className="container mx-auto p-2">
        <hr className="border-t-1 border-blue-300" />
      </div>

      <div
        id="TenantDataTable"
        className="container mx-auto p-2 overflow-x-auto"
      >
        <div className="bg-blue-200">
          <button
            className={`${filter}`}
            onClick={() => setToggleFilter(!toggleFilter)}
          >
            <span className="text-lg">Filter</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 transform transition-transform duration-300 ${toggleFilter ? "rotate-180" : ""
                }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {/* MARK: FILTER */}
          {toggleFilter && (
            <div className={`${filter_container} py-2`}>
              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2`}>
                <input
                  type="text"
                  placeholder="Tenant ID"
                  className={`input w-full py-1`}
                  onChange={(e: any) =>
                    setTenantReq({
                      ...tenantReq,
                      tenantId:
                        e.target.value !== "" ? parseInt(e.target.value) : null,
                    })
                  }
                ></input>
              </div>

              <div className={`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2`}>
                <button onClick={getAllTenants} className={`btn primary w-full py-1`}>
                  Search
                </button>
              </div>
            </div>
          )}
        </div>
        
        {/* MARK: TABLE */}
        <form id="editTenantForm" onSubmit={onEditTenantFormSubmit} ref={editTenantFormRef}/>
        <table className="min-w-full text-center">
          <thead>
            <tr>
              <th className={`border py-1 text-center text-md`}>Tenant ID</th>
              <th className={`border py-1 text-center text-md`}>Name</th>
              <th className={`border py-1 text-center text-md`}>Description</th>
              <th className={`border py-1 text-center text-md`}>Location</th>
              <th className={`border py-1 text-center text-md w-2/12`}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            ) : (
              tenantData?.map((tenant) => {
                let locationName = locations?.filter(
                  (x) => x.locationId === tenant.locationId
                )[0];

                return (
                  <React.Fragment key={tenant.tenantId}>
                    {editTenantFormValues?.tenantId !== tenant.tenantId ? 
                    //MARK: VIEW TENANT ROW
                    (
                      <tr className="hover:bg-blue-100">
                        <td className="border py-1">{tenant.tenantId}</td>
                        <td className="border py-1">{tenant.tenantShortName}</td>
                        <td className="border py-1">{tenant.tenantDescription}</td>
                        <td className="border py-1">{locationName?.locationName}</td>
                        <td className="border py-1 flex gap-2 justify-center px-2">
                          <button
                            className="btn outline-b flex-1"
                            onClick={() => {
                                setEditTenantFormValues(tenant);
                                setIsEditTenantFormValid(false);
                              }
                            }
                          >
                            Edit
                          </button>
                          
                          <button
                            className="btn outline-b flex-1"
                            onClick={() => {
                                setSelectedTenant(tenant);
                                setShowModal(true);
                              }
                            }
                          >
                            Settings
                          </button>
                        </td>
                      </tr>  
                    )
                    :
                    //MARK: EDIT TENANT ROW
                    (
                      <tr className="hover:bg-blue-200">
                        <td className="border py-1">{tenant.tenantId}</td>
                        {/* TENANT NAME */}
                        <td className="border px-2">
                          <input 
                            required
                            form="editTenantForm"
                            type="text" 
                            name="tenantShortName"
                            id="tenantShortName"
                            value={editTenantFormValues?.tenantShortName}
                            className="input py-1 w-full"
                            onChange={(e) => {
                              setEditTenantFormValues({...editTenantFormValues, tenantShortName: e.target.value})
                              onEditTenantFormFieldChange();
                              }
                            }
                          />
                        </td>
                        
                        {/* TENANT DESCRIPTION */}
                        <td className="border px-2">
                          <input
                            required
                            form="editTenantForm"
                            type="text"
                            name="tenantDescription"
                            id="tenantDescription"
                            value={editTenantFormValues?.tenantDescription}
                            className="input py-1 w-full"
                            onChange={(e) => {
                              setEditTenantFormValues({...editTenantFormValues, tenantDescription: e.target.value});
                              onEditTenantFormFieldChange();
                            }}
                          />
                        </td>

                        {/* TENANT LOCATION */}
                        <td className="border px-2">
                          <select 
                            required
                            form="editTenantForm"
                            name="locationId" 
                            id="locationId"
                            value={editTenantFormValues?.locationId}
                            className="select w-full py-[0.4em]"
                            onChange={(e) => {
                                setEditTenantFormValues({...editTenantFormValues, locationId: parseInt(e.target.value)})
                                onEditTenantFormFieldChange();
                              }
                            }
                          >
                            {locations?.map((location) => (
                              <option
                                key={location.locationId}
                                value={location.locationId}
                              >
                                {location.locationName}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td className="border flex flex-1 gap-2 justify-center px-2 py-1">
                          <button
                            form="editTenantForm"
                            type="submit"
                            className="btn outline-b flex-1"
                            disabled={isEditTenantFormValid ? false : true}
                          >
                            Save
                          </button>
                          <button
                            className="btn outline-b flex-1"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditTenantFormValues(null);
                                setIsEditTenantFormValid(false);
                              }
                            }
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    )
                    }
                  </React.Fragment>
                )
              })
            )}
          </tbody>
        </table>
      </div>

      {/* MARK: Tenant SETTINGS MODAL*/}
      {showModal === true && selectedTenant && (
        <Modal onClose={toggleModal}>
          <section
            id="OperatorModal"
            className="bg-white w-full h-full rounded-lg "
          >
            <div id="OperatorButtons" className="flex">
              <button
                className="p-2 border border-b-0"
                style={
                  DetailsDisplay === true
                    ? {
                      backgroundColor:
                        "rgb(219 234 254 / var(--tw-bg-opacity))",
                    }
                    : {}
                }
                onClick={() => handleDisplay(0, selectedTenant.tenantId)}
              >
                Details
              </button>
              <button
                className="p-2 border"
                style={
                  SettingsDisplay === true
                    ? {
                      backgroundColor:
                        "rgb(219 234 254 / var(--tw-bg-opacity))",
                    }
                    : {}
                }
                onClick={() => handleDisplay(1, selectedTenant.tenantId)}
              >
                Settings
              </button>
              <button
                className="p-2 border"
                style={
                  LocationDisplay === true
                    ? {
                      backgroundColor:
                        "rgb(219 234 254 / var(--tw-bg-opacity))",
                    }
                    : {}
                }
                onClick={() => handleDisplay(2, selectedTenant.tenantId)}
              >
                Location
              </button>
            </div>

            <div
              id="OperatorDisplayArea"
              className="w-full h-[90%] border overflow-y-auto max-h-[90%]"
            >
              {DetailsDisplay === true && (
                <div id="DetailsDisplay" className="flex flex-col p-2">
                  <span>ID: {selectedTenant.tenantId}</span>
                  <span>Tenant: {selectedTenant.tenantShortName}</span>
                  <span>Description: {selectedTenant.tenantDescription}</span>
                </div>
              )}

              {SettingsDisplay === true && (
                <div id="SettingDisplay" className="flex flex-col p-2">
                  {/* MARK: CREATE SETTING */}
                  <div className="border border-rounded p-2" aria-colspan={4}>
                    <div>
                      <button
                        className={`${modal_filter}`}
                        onClick={() =>
                          setToggleCreateSetting(!toggleCreateSetting)
                        }
                      >
                        <span>Create Setting</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 transform transition-transform duration-300 ${toggleCreateSetting ? "rotate-180" : ""
                            }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {toggleCreateSetting && (
                        <div className={`${modal_filter_container}`}>
                          <div className={`${modal_filter_content}`}>
                            <select
                              className={`select w-full text-sm py-[0.4em]`}
                              onChange={(e) =>
                                handleSettingChange(e, selectedTenant)
                              }
                            >
                              {settingTypes &&
                                settingTypes.map((st) => (
                                  <option
                                    value={st.settingTypeId}
                                    key={"settingType-" + st.settingTypeId}
                                  >
                                    {st.settingTypeName}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className={`${modal_filter_content}`}>
                            <button
                              className={`btn primary w-full py-1 text-sm`}
                              onClick={() => pushSetting(selectedTenant)}
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* CURRENT SETTINGS */}
                  <div
                    id="CurrentSettings"
                    className="flex flex-col items-center justify-center mt-5"
                  >
                    <table className="min-w-full text-center">
                      <thead>
                        <tr>
                          <th className={`border py-1 text-center text-md`}>ID</th>
                          <th className={`border py-1 text-center text-md`}>Type</th>
                          <th className={`border py-1 text-center text-md`}>Description</th>
                          <th className={`border py-1 text-center text-md`}>Value</th>
                          <th className={`border py-1 text-center text-md`}>Region</th>
                          <th className={`border py-1 text-center text-md`}>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredSettings && filteredSettings.length > 0 ? (
                          filteredSettings.map((fs, index) => {
                            const asType = settingTypes?.filter(
                              (i) => i.settingTypeId === fs.settingTypeId
                            )[0];

                            if (
                              editOpSettings &&
                              editOpSettings === true &&
                              editSelectedSetting &&
                              editSelectedSetting.settingId === fs.settingId
                            ) {
                              // EDIT MODE
                              return (
                                <tr
                                  key={"setting-" + fs.settingId + "-" + index}
                                >
                                  <td className={`border py-1 text-center`}>
                                    {fs.settingId}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {asType?.settingTypeName}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setEditSelectedSettings({ ...editSelectedSetting, settingStringValue: e.target.value })}
                                      value={
                                        editSelectedSetting.settingStringValue
                                      }
                                      className={`input w-full py-1`}
                                      placeholder="Enter Value Here"
                                    ></input>
                                  </td>
                                  <td className={`border py-1 text-center px-2`}>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setEditSelectedSettings({ ...editSelectedSetting, settingNumericValue: parseInt(e.target.value) })}
                                      value={
                                        editSelectedSetting.settingNumericValue
                                      }
                                      className={`input w-full py-1`}
                                      placeholder="Enter Description Here"
                                    ></input>
                                  </td>
                                  <td className={`border py-1 text-center px-2`}>
                                    <select
                                      className={`select w-full py-[0.4em]`}
                                      onChange={(e) =>
                                        setEditSelectedSettings({ ...editSelectedSetting, regionId: parseInt(e.target.value) })
                                      }
                                      value={editSelectedSetting.regionId}
                                    >
                                      {regions &&
                                        regions.map((r, index) => (
                                          <option
                                            key={`region-${r.regionId}-${index}`}
                                            value={r.regionId}
                                          >
                                            {r.regionName}
                                          </option>
                                        ))}
                                    </select>
                                  </td>

                                  <td className={`border py-1 text-center`}>
                                    <div className="flex gap-2 justify-center px-1">
                                      <button
                                        key={`save-${fs.settingId}-index`}
                                        className={`btn outline-b flex-1`}
                                        onClick={() =>
                                          handleSaveEditSetting(
                                            editSelectedSetting
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                    
                                      <button
                                        key={`cancel-${fs.settingId}-index`}
                                        className={`btn outline-b flex-1`}
                                        onClick={() =>
                                          setEditOpSettings(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else {
                              // !EDIT MODE
                              const newRegion = regions && regions.filter((rr) => rr.regionId === fs.regionId).length > 0 ? regions.filter((rr) => rr.regionId === fs.regionId)[0].regionName : '';

                              return (
                                <tr
                                  key={"setting-" + fs.settingId + "-" + index}
                                >
                                  <td className={`border py-1 text-center`}>
                                    {fs.settingId}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {asType?.settingTypeName}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {fs.settingStringValue}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {fs.settingNumericValue}
                                  </td>

                                  <td className={`border py-1 text-center`}>
                                    {newRegion}
                                  </td>

                                  <td className={`border py-1 text-center`}>
                                    <button
                                      key={`edit-${fs.settingId}-index`}
                                      className={`btn outline-b w-3/4`}
                                      onClick={async () => {
                                        await handleEditOperatorSettings(fs);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className={`border py-1 text-center`}>
                              No Settings Set
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* MARK: CREATE LOCATION */}
              {LocationDisplay === true && (
                <div id="LocationDisplay" className="flex flex-col p-2">
                  {/* ADD Location */}
                  <div className="border border-rounded p-2" aria-colspan={4}>
                    <div>
                      <button
                        className={`${modal_filter}`}
                        onClick={() =>
                          setToggleCreateLocation(!toggleCreateLocation)
                        }
                      >
                        <span>Create Location</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 transform transition-transform duration-300 ${toggleCreateLocation ? "rotate-180" : ""
                            }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {toggleCreateLocation && (
                        /* CREATE LOCATION FORM */
                        <form onChange={onCreateLocationFormChange} onSubmit={onCreateLocationFormSubmit} className={`${modal_filter_container}`}>
                          {/* LOCATION NAME */}
                          <div className={`${modal_filter_content}`}>
                            <input
                              required
                              id="locationName"
                              name="locationName"
                              type="text"
                              className={`input w-full py-1`}
                              placeholder="Location Name"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  locationName: e.target.value,
                                })
                              }
                            ></input>
                          </div>
                          {/* LOCATION DESCRIPTION */}
                          <div className={`${modal_filter_content}`}>
                            <input
                              id="locationDescription"
                              name="locationDescription"
                              type="text"
                              className={`input w-full py-1`}
                              placeholder="Location Description"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  locationDescription: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION REGION */}
                          <div className={`${modal_filter_content}`}>
                            <select
                              required={true}
                              id="regionId"
                              name="regionId"
                              className={`select w-full py-[0.4em]`}
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  regionId: parseInt(e.target.value),
                                })
                              }
                            >
                              <option value="" disabled selected>-- Region --</option>
                              {regions &&
                                regions.map((r, index) => (
                                  <option
                                    key={`locRegion-${r.regionId}-${index}`}
                                    value={r.regionId}
                                  >
                                    {r.regionName}
                                  </option>
                                ))}
                            </select>
                          </div>

                          {/* LOCATION COUNTRY */}
                          <div className={`${modal_filter_content}`}>
                            <select
                              required={true}
                              id="countryId"
                              name="countryId"
                              className={`select w-full py-[0.4em]`}
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  regionId: parseInt(e.target.value),
                                })
                              }
                            >
                              <option value="" disabled selected>-- Country --</option>
                              {countries &&
                                countries.map((c, index) => (
                                  <option
                                    key={`locRegion-${c.isoCode}-${index}`}
                                    value={c.countryId}
                                  >
                                    {c.countryName}
                                  </option>
                                ))}
                            </select>
                          </div>

                          {/* LOCATION ADDRESS L1*/}
                          <div className={`${modal_filter_content}`}>
                            <input
                              id="addressLine1"
                              name="addressLine2"
                              required
                              type="text"
                              className={`input w-full py-1`}
                              placeholder="Address Line 1"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  addressLine1: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION ADDRESS L2*/}
                          <div className={`${modal_filter_content}`}>
                            <input
                              type="text"
                              className={`input w-full py-1`}
                              defaultValue={""}
                              placeholder="Address Line 2"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  addressLine2: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION EMAIL*/}
                          <div className={`${modal_filter_content}`}>
                            <input
                              required
                              id="email1"
                              name="email1"
                              type="email"
                              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                              className={`input w-full py-1`}
                              placeholder="Email Address"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  email1: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION EMAIL ALT */}
                          <div className={`${modal_filter_content}`}>
                            <input
                              id="email2"
                              name="email2"
                              defaultValue={""}
                              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                              type="text"
                              className={`input w-full py-1`}
                              placeholder="Email Address - Alternate"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  email2: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION PHONE NUMBER */}
                          <div className={`${modal_filter_content}`}>
                            <input
                              required
                              id="phone1"
                              name="phone1"
                              type="tel"
                              pattern="^\+?\d{10,}$"
                              className={`input w-full py-1`}
                              placeholder="Phone Number"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  phone1: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* LOCATION PHONE NUMBER ALT */}
                          <div className={`${modal_filter_content}`}>
                            <input
                              id="phone2"
                              name="phone2"
                              type="text"
                              className={`input w-full py-1`}
                              placeholder="Phone Number - Alternate"
                              pattern="^\+?\d{10,}$"
                              onChange={(e) =>
                                setCreateLocationReq({
                                  ...createLocationReq,
                                  phone2: e.target.value,
                                })
                              }
                            ></input>
                          </div>

                          {/* SUBMIT BUTTON */}
                          <div className={`${modal_filter_content}`}>
                            <button
                              type="submit"
                              disabled={createLocationValid ? false : true}
                              className={`btn primary w-full py-1`}
                              onClick={() =>
                                createTenantLocation(selectedTenant.tenantId)
                              }
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>

                  {/* MARK: CURRENT Locations */}
                  <div
                    id="CurrentLocations"
                    className="flex flex-col items-center justify-center mt-5"
                  >
                    <table className="min-w-full text-center">
                      <thead>
                        <tr>
                          <th className={`border py-1 text-center text-md`}>ID</th>
                          <th className={`border py-1 text-center text-md`}>Location</th>
                          <th className={`border py-1 text-center text-md`}>Region</th>
                          <th className={`border py-1 text-center text-md`}>Description</th>
                          <th className={`border py-1 text-center text-md`}>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredLocations && filteredLocations.length > 0 ? (
                          filteredLocations.map((fl, index) => {
                            if (
                              editTenantLocation &&
                              editTenantLocation === true &&
                              editSelectedLocation &&
                              editSelectedLocation.locationId === fl.locationId
                            ) {
                              // EDIT MODE
                              return (
                                <tr
                                  key={"setting-" + fl.locationId + "-" + index}
                                >
                                  <td className={`border py-1 text-center`}>
                                    {fl.locationId}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setEditSelectedLocation({
                                          ...editSelectedLocation,
                                          locationName: e.target.value,
                                        })
                                      }
                                      value={editSelectedLocation.locationName}
                                      className={`input`}
                                      placeholder="Location Name"
                                    ></input>
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    <select
                                      onChange={(e) =>
                                        setEditSelectedLocation({
                                          ...editSelectedLocation,
                                          regionId: parseInt(e.target.value),
                                        })
                                      }
                                      className={`select`}
                                    >
                                      {regions &&
                                        regions.map((r, index) => (
                                          <option
                                            key={`region-${r.regionId}-${index}`}
                                            value={r.regionId}
                                          >
                                            {r.regionName}
                                          </option>
                                        ))}
                                    </select>
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setEditSelectedLocation({
                                          ...editSelectedLocation,
                                          locationDescription: e.target.value,
                                        })
                                      }
                                      value={
                                        editSelectedLocation.locationDescription
                                      }
                                      className={`input`}
                                      placeholder="Enter Description Here"
                                    ></input>
                                  </td>

                                  <td className={`border py-1 text-center`}>
                                    <div className="flex gap-2 justify-center mx-2">
                                        <button
                                          key={`save-${fl.locationId}-${index}`}
                                          className={`btn outline-b flex-1`}
                                          onClick={() =>
                                            updateTenantLocation(
                                              editSelectedLocation
                                            )
                                          }
                                        >
                                          Save
                                        </button>
                                      
                                        <button
                                          key={`cancel-${fl.locationId}-${index}`}
                                          className={`btn outline-b flex-1`}
                                          onClick={() =>
                                            setEditTenantLocation(false)
                                          }
                                        >
                                          Cancel
                                        </button>
                                    </div>

                                      
                                  </td>
                                </tr>
                              );
                            } else {
                              // !EDIT MODE
                              const regionFil = regions ? regions.filter((rr) => rr.regionId === fl.regionId) : []
                              const newRegion = regionFil.length > 0 ? regionFil[0].regionName : '';

                              return (
                                <tr key={`location-${fl.locationId}-${index}`}>
                                  <td className={`border py-1 text-center`}>
                                    {fl.locationId}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {fl.locationName}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {newRegion}
                                  </td>
                                  <td className={`border py-1 text-center`}>
                                    {fl.locationDescription}
                                  </td>

                                  <td className={`border py-1 text-center`}>
                                    <button
                                      key={`edit-${fl.locationId}-${index}`}
                                      className={`btn outline-b w-3/4`}
                                      onClick={async () => {
                                        handleEditTenantLocation(fl);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className={`border py-1 text-center`}>
                              No Locations Created
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </section>
        </Modal>
      )}

      <ToastContainer></ToastContainer>
    </div>
  );
};

export default TenantPage;
