import axios from 'axios';
import { sportRequest, eventRequest, tournamentRequest, categoryRequest, betTypeRequest, betTypeSpecificRequest, marketRequest, eventProfit, marketProfit, betTypeProfit, EventBetTypesRequest } from '../Request';
import { Event, EventBetTypesRes, Market } from '../types';

const baseUrl = process.env.REACT_APP_BASE_EVENT;
const subURL = process.env.REACT_APP_SUB_EVENT;

export class EventService {
  // Field Sheet Nav Bar
  static async GetSport<T>(params: sportRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/sport?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async GetCategory<T>(params: categoryRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/category?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async GetTournament<T>(params: tournamentRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/tournament?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async GetEvent<T>(params: eventRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/event?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } 
  
  static async UpdateEvent<T>(params: Partial<Event>, token: string | null){
    try{
      const response = await axios.post<T>(`${baseUrl}${subURL}/event`, params, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log('Error updating event', error);
      throw error;
    }
  }

  // Field Sheet Bet Types
  static async GetBetType<T>(params: betTypeRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/bettype?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }  

  static async GetBetTypeSpecific<T>(params: betTypeSpecificRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/bettypespecific?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }  


  // Fieldsheet Markets
  static async GetMarket<T>(params: marketRequest, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/market?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }  

  static async UpdateMarket<T>(params: Market, token: string | null){
    try{
      const response = await axios.post<T>(`${baseUrl}${subURL}/market`, params, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log('Error updating event', error);
      throw error;
    }
  }


  // Proft & Loss
  static async EventProfit<T>(params: eventProfit, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/eventProfit?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } 

  static async MarketProfit<T>(params: marketProfit, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/marketProfit?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } 

  static async BetTypeProfit<T>(params: betTypeProfit, token: string | null) {
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }
    
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/betTypeProfit?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  
  // Status Codes
  static async MarketStatus<MarketStatusRes>(token: string | null) {    
    try {
      const response = await axios.get<MarketStatusRes>(`${baseUrl}${subURL}/marketstatus`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  
  static async EventStatus<EventStatusRes>(token: string | null) {    
    try {
      const response = await axios.get<EventStatusRes>(`${baseUrl}${subURL}/eventstatus`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async EventBetTypes<EventBetTypesRes>(params: EventBetTypesRequest, token: string | null){
    let urlParams = '';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    try{
      const response = await axios.get<EventBetTypesRes>(`${baseUrl}${subURL}/eventbettype?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  

  static async UpdateBetType<T>(params: EventBetTypesRes, token: string | null){
    try{
      const response = await axios.post<T>(`${baseUrl}${subURL}/eventbettype`, params, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log('Error updating event', error);
      throw error;
    }
  }
}