import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Main from './pages/Main';
import ForgotPasswordPage from './pages/ForgotPassword';

function App() {
  // Function to check if user is logged in
  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  // PrivateRoute component to guard routes
  const PrivateRoute = ({ element }: any) => {
    return isAuthenticated() ? (
      element
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route
          path="/*"
          element={<PrivateRoute element={<Main />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
