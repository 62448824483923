import React, { useEffect, useRef, useState } from 'react'
import { CountryRes, RegionRes, RegionTaxRes, Sport, TransactionTypeRes } from '../types';
import { ControlService } from '../services/ControlService';
import { ResponseService } from '../services/ResponseService';
import Modal from '../components/Modal';
import { ToastContainer } from 'react-toastify';
import { RegionTaxReq, sportRequest } from '../Request';
import { EventService } from '../services/EventService';

const RegionPage: React.FC = () => {
    //MARK: PAGE STATE
    const token = localStorage.getItem('token');
    const [countries, setCountries] = useState<CountryRes[]>([]);
    const [regions, setRegions] = useState<RegionRes[]>([]);

    //default values for sport requests
    const sportRequestDefaultValues: sportRequest = {
        dataProviderId: 1,
        sportId: null,
        startDate: null,
        endDate: null,
        eventStatusId: null,
    }

    const [sportRequestValues, setSportRequestValues] = useState<sportRequest>({ ...sportRequestDefaultValues });

    const GetCountries = async () => {
        await ControlService.GetCountries<CountryRes[]>(token)
            .then((res) => {
                setCountries(res);
            })
            .catch((error) => {
                console.log("Retrieve Country Error:", error);
                ResponseService.failMessage("Failed to retrieve countries");
            });
    };

    const GetRegions = async () => {
        await ControlService.GetRegions<RegionRes[]>(token)
            .then((res) => {
                setRegions(res);
                setFilteredRegions(res);
            })
            .catch((error) => {
                console.log("Retrieve Region Error:", error);
                ResponseService.failMessage("Failed to retrieve regions");
            });
    }
    
    //MARK: PAGE USE EFFECT
    useEffect(() => {
        GetCountries();
        GetRegions();
        getSports();
        getTransactionTypes();
    }, []);


    //MARK: CREATE REGION
    const [toggleCreateRegion, setToggleCreateRegion] = React.useState(false);

    const createRegionDefaultValues: RegionRes = {
        countryId: 0,
        regionId: 0,
        regionName: ""
    }

    //new region state value, assign default values
    const [createRegionValues, setCreateRegionValues] = React.useState<RegionRes>({ ...createRegionDefaultValues })

    //ref to form for validity checks
    const createRegionFormRef = useRef<HTMLFormElement>(null);

    //form validity values
    const [createRegionFormValid, setCreateRegionFormValid] = useState<boolean>(false);

    //validates the form
    function validateCreateRegionForm(): boolean {
        const validity = createRegionFormRef.current?.checkValidity();
        if (validity === true) {
            return true;
        } else {
            return false;
        }
    }

    //assigned to the create region form, runs whenever a value in the form changes
    function handleCreateRegionFormFieldChange() {
        setCreateRegionFormValid(validateCreateRegionForm());
    }

    //runs on submission of the create region form
    async function handleCreateRegionFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (validateCreateRegionForm()) {
            console.table(createRegionValues);

            //make post here
            await ControlService.PostRegion<RegionRes>(createRegionValues, token);

            //refetch
            GetRegions();

            //reset form
            setCreateRegionValues({ ...createRegionDefaultValues });
            setCreateRegionFormValid(false);

        } else {
            ResponseService.failMessage('Invalid Input');
        }
    }

    //MARK: FILTER REGIONS
    const [filteredRegions, setFilteredRegions] = useState<RegionRes[]>([]);
    const [toggleFilterRegion, setToggleFilterRegion] = React.useState(false);
    const [regionNameFilterValue, setRegionNameFilterValue] = useState("");


    function handleFilterRegionFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        filterRegions();
    }

    function filterRegions() {
        setFilteredRegions(regions.filter(region => region.regionName.toLowerCase().includes(regionNameFilterValue.toLowerCase())));
    }

    //MARK: REGION INLINE EDITING
    const [editRegionFormValues, setEditRegionFormValues] = useState<RegionRes | null>(null);
    const editRegionFormRef = useRef<HTMLFormElement>(null);

    const [editRegionFormValid, setEditRegionFormValid] = useState<boolean>(false);

    function validateEditRegionForm(): boolean {
        const validity = editRegionFormRef.current?.checkValidity();
        if (validity === true) {
            return true;
        } else {
            return false;
        }
    }

    function handleEditRegionFormFieldChange() {
        setEditRegionFormValid(validateEditRegionForm());
    }

    async function handleEditRegionFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (validateEditRegionForm() && editRegionFormValues) {
            console.table(editRegionFormValues);
            //make post here
            const res = await ControlService.PostRegion<RegionRes>(editRegionFormValues, token);
            if (res) {
                //refetch
                GetRegions();

                ResponseService.successMessage(`Updated Region with ID: ${editRegionFormValues.regionId}`);

                setEditRegionFormValues(null);
                setEditRegionFormValid(false);
            }

            
        } else {
            ResponseService.failMessage('Invalid Input');
        }
    }


    //MARK: MODAL STATE
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedRegion, setSelectedRegion] = useState<RegionRes | undefined>();
    const [regionModalTab, setRegionModalTab] = useState<number>(0);

    //default modal switching values
    function resetModal() {
        setSelectedRegion(undefined);
        setRegionModalTab(1);
        setShowModal(!showModal);
    }

    //MARK: REGION TAXES TAB
    //run whenever selected region changes and has a value
    useEffect(() => {
        if (selectedRegion !== undefined) {
            //get region taxes for the selected region
            //ResponseService.successMessage(`Selected ${selectedRegion.regionName}`)
            getRegionTax();
        }
    },[selectedRegion])

    const [sports, setSports] = useState<Sport[]>([]);
    const [transactionTypes, setTransactionTypes] = useState<TransactionTypeRes[]>([]);
    

    //region tax linked fields
    async function getSports() {
        const res = await EventService.GetSport<Sport[]>(sportRequestValues, token);
        setSports(res);
    }

    async function getTransactionTypes() {
        const res = await ControlService.GetTransactionTypes<TransactionTypeRes[]>(token);
        setTransactionTypes(res);
    }



    //REGIONTAXES LIST
    const [regionTaxes, setRegionTaxes] = useState<RegionTaxRes[]>([]);

    async function getRegionTax() {
        if (selectedRegion) {
            const res = await ControlService.GetRegionTax<RegionTaxRes>({ regionId: selectedRegion?.regionId }, token);
            setRegionTaxes(res);
        }
    }

    //MARK: CREATE REGION TAX FORM
    //default values for region tax requests
    const defaultCreateRegionTaxValues: RegionTaxReq = {
        regionId: selectedRegion?.regionId || 0,
        dataProviderId: 1,
        isActive: true,
        sportId: 0,
        taxFormula: '',
        taxRatePercentage: 0,
        taxTypeCode: '',
        transactionTypeId: 0,
    }

    //create regionTax form values
    const [createRegionTaxValues, setRegionTaxValues] = useState<RegionTaxReq>({ ...defaultCreateRegionTaxValues });

    const createRegionTaxFormRef = useRef<HTMLFormElement>(null);
    const [showCreateRegionTaxForm, setShowCreateRegionTaxForm] = useState(false);
    const [createRegionTaxFormValid, setCreateRegionTaxFormValid] = useState(false);

    function validateCreateRegionTaxForm() {
        const validity = createRegionTaxFormRef.current?.checkValidity();

        if (validity === true) {
            return true;
        } else {
            return false;
        }
    }

    function handleCreateRegionTaxFormFieldChange() {
        const validity = validateCreateRegionTaxForm();
        if (validity === true) {
            setCreateRegionTaxFormValid(true);
        } else {
            setCreateRegionTaxFormValid(false);
        }
    }

    async function handleCreateRegionTaxFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const validity = validateCreateRegionTaxForm();
        if (validity === true) {
            const res = await ControlService.CreateRegionTax<RegionTaxRes>(createRegionTaxValues, token);
            if (res) {
                await getRegionTax();
                setRegionTaxValues({ ...defaultCreateRegionTaxValues });
                setCreateRegionTaxFormValid(false);
            }
        }
    }

    //MARK: JSX
    return (
        <div className='container mx-auto p-2'>
            {/* MARK: CREATE REGION DIV*/}
            <div className='bg-blue-200'>
                <button
                    className="w-full text-left text-lg px-4 py-2 border-y border-blue-200 flex justify-between items-center"
                    onClick={() => setToggleCreateRegion(!toggleCreateRegion)}
                >
                    <span>Create Region</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-6 w-6 transform transition-transform duration-300 ${toggleCreateRegion ? "rotate-180" : ""
                            }`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                {toggleCreateRegion && (
                    /* MARK: CREATE REGION FORM */
                    <form
                        ref={createRegionFormRef}
                        onChange={handleCreateRegionFormFieldChange}
                        onSubmit={handleCreateRegionFormSubmit}
                        className="flex flex-wrap items-center justify-end bg-blue-100 border-blue-300">

                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                            {/* REGION NAME */}
                            <input type="text"
                                autoComplete="off"
                                required={true}
                                id="regionName"
                                name="regionName"
                                value={createRegionValues.regionName}
                                onChange={(e) => { setCreateRegionValues({ ...createRegionValues, regionName: e.target.value }) }}
                                className="input w-full py-1 text-sm"
                                placeholder="Region Name"
                            />
                        </div>

                        <div className='w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2'>
                            {/* COUNTRY */}
                            <select
                                required={true}
                                id="countryId"
                                name="countryId"
                                value={createRegionValues.countryId}
                                onChange={(e) => setCreateRegionValues({ ...createRegionValues, countryId: parseInt(e.target.value) })}
                                className="select w-full py-[0.4em] text-sm"
                            >
                                <option value="">--Select Country--</option>
                                {countries.map((country) => (
                                    <option key={country.countryId} value={country.countryId}>
                                        {country.countryName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                            {/* CREATE BUTTON */}
                            <button
                                disabled={createRegionFormValid ? false : true}
                                className="btn primary w-full py-1 text-sm"
                                type="submit"
                            >
                                Create Region
                            </button>
                        </div>
                    </form>
                )}
            </div>

            {/* MARK: FILTER SECTION */}
            <div className="bg-blue-200 mt-4">
                <button
                    className="w-full text-left text-lg px-4 py-2 border-y border-blue-200 flex justify-between items-center"
                    onClick={() => setToggleFilterRegion(!toggleFilterRegion)}
                >
                    <span>Filter</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-6 w-6 transform transition-transform duration-300 ${toggleFilterRegion ? "rotate-180" : ""
                            }`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>

                {toggleFilterRegion && (
                    /* FILTER FORM */
                    <form
                        onSubmit={handleFilterRegionFormSubmit}
                        className="flex flex-wrap items-center justify-end bg-blue-100 border-blue-300">

                        {/* REGION ID */}
                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                            <input
                                id="filterRegionName"
                                name="filterRegionName"
                                type="text"
                                placeholder="Region Name"
                                className="input w-full py-1 text-sm"
                                value={regionNameFilterValue}
                                onChange={(e) => setRegionNameFilterValue(e.target.value)}
                            />
                        </div>

                        {/* FILTER BUTTON */}
                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                            <button
                                type="submit"
                                className="btn primary w-full py-1 text-sm">Filter</button>
                        </div>

                    </form>
                )}
            </div>

            {/* MARK: REGION TABLE */}
            <form id="editRegionForm" ref={editRegionFormRef} onSubmit={handleEditRegionFormSubmit}/>
            <table className="min-w-full text-center">
                <thead>
                    <tr>
                        <th className="border px-4 py-2">Region ID</th>
                        <th className="border px-4 py-2">Region Name</th>
                        <th className="border px-4 py-2">Country Name</th>
                        <th className="border px-4 py-2 w-1/4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredRegions.map((region) => {
                        const country = countries.find((c) => c.countryId === region.countryId)?.countryName;

                        return (
                            <React.Fragment key={region.regionId}>
                                {editRegionFormValues?.regionId !== region.regionId ? 
                                //MARK: VIEW ROW
                                (
                                    <tr className="hover:bg-blue-100">
                                    <td className="border px-4 py-2">{region.regionId}</td>
                                    <td className="border px-4 py-2">{region.regionName}</td>
                                    <td className="border px-4 py-2">{country ? country : ''}</td>
                                    <td className="border px-4 py-2">

                                        <div className="flex gap-2 justify-center">
                                            <button
                                                type="button"
                                                className='btn outline-b px-2 w-1/3'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditRegionFormValues(region);
                                                    setEditRegionFormValid(false);
                                                }}
                                            >
                                                Edit Region
                                            </button>
                                            <button
                                                type="button"
                                                className='btn outline-b px-2 w-1/3'
                                                onClick={() => { setSelectedRegion(region); setRegionModalTab(1); setShowModal(true) }}
                                            >
                                                Region Taxes
                                            </button>
                                        </div>

                                    </td>
                                </tr>
                                ) : 
                                //MARK: EDIT ROW
                                (
                                <tr role="form" className="hover:bg-blue-200">
                                    {/* REGION ID (uneditable) */}
                                    <td className="border px-4">
                                        {region.regionId}
                                    </td>

                                    <td className="border">
                                        {/* REGION NAME */}
                                        <input
                                            form="editRegionForm"
                                            type="text"
                                            id="regionName"
                                            name="regionName"
                                            placeholder="Region Name"
                                            className="input w-3/4 m-2 py-1 text-sm"
                                            value={editRegionFormValues?.regionName}
                                            onChange={(e) => {
                                                setEditRegionFormValues({
                                                    ...editRegionFormValues,
                                                    regionName: e.target.value
                                                });
                                                handleEditRegionFormFieldChange();
                                            }}
                                        />
                                    </td>

                                    <td className="border">
                                        {/* COUNTRY */}
                                        <select 
                                            form="editRegionForm"
                                            id="countryId"
                                            name="countryId"
                                            value={editRegionFormValues?.countryId}
                                            className="select py-[0.4em] text-sm w-3/4"
                                            onChange={(e) => {
                                                setEditRegionFormValues({
                                                    ...editRegionFormValues,
                                                    countryId: Number(e.target.value)
                                                });
                                                handleEditRegionFormFieldChange();
                                            }}
                                        >
                                            {countries.map((country) => (
                                            <option key={country.countryId} value={country.countryId}>
                                                {country.countryName}
                                            </option>
                                        ))}
                                        </select>
                                    </td>

                                    <td className="border">
                                        <div className="flex justify-center gap-2">
                                            <button
                                                form="editRegionForm"
                                                type="submit"
                                                disabled={editRegionFormValid ? false : true}
                                                className="btn outline-b px-2 w-1/3"
                                            >
                                                Save
                                            </button>
                                            <button 
                                                className="btn outline-b px-2 w-1/3"
                                                onClick={() => {setEditRegionFormValues(null)}}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </td>
                                    
                                </tr>
                                )}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>

            {/* MARK: MODAL */}
            {showModal === true && selectedRegion && (
                <Modal onClose={() => { resetModal() }}>
                    {/* Modal tabs */}
                    <section className="flex">
                        <button
                            className={`btn tab p-2 ${regionModalTab === 1 ? 'active' : ''}`}
                            onClick={() => setRegionModalTab(1)}
                        >
                            Region Taxes
                        </button>
                    </section>
                    {/* Modal Area */}
                    <div className="w-full h-[90%] border overflow-y-auto max-h-[90%]">
                        {regionModalTab === 0 && 
                            <>
                                <p className="px-4 py-2">Region ID: {selectedRegion.regionId}</p>
                                <p className="px-4 py-2">Region Name: {selectedRegion.regionName}</p>
                            </>
                        }
                        {regionModalTab === 1 && 
                            //MARK: REGION TAXES TAB
                            //<RegionTaxComponent regionId={selectedRegion.regionId}/>
                            <>
                                <button
                                    className="w-full text-left text-lg px-4 py-2 border-y flex justify-between items-center"
                                    onClick={() => {setShowCreateRegionTaxForm(!showCreateRegionTaxForm);}}
                                >
                                    <span>Create Tax Region</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`h-6 w-6 transform transition-transform duration-300 ${showCreateRegionTaxForm ? "rotate-180" : ""
                                            }`}
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {showCreateRegionTaxForm && (
                                    //MARK: CREATE REGION TAX FORM
                                    <form
                                        ref={createRegionTaxFormRef}
                                        onChange={handleCreateRegionTaxFormFieldChange}
                                        onSubmit={handleCreateRegionTaxFormSubmit}
                                        className="flex flex-wrap items-center justify-end border border-t-0"
                                    >
                                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                                            {/* TRANSACTION TYPE ID */}
                                            <select
                                                required={true}
                                                name="transactionTypeId"
                                                id="transactionTypeId"
                                                className="select w-full py-[0.4em] text-sm"
                                                value={createRegionTaxValues.transactionTypeId}
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, transactionTypeId: parseInt(e.target.value) });
                                                }}
                                            >
                                                <option value="">--Select Transaction Type--</option>
                                                {transactionTypes.map((transactionType) => (
                                                    <option key={transactionType.transactionTypeId} value={transactionType.transactionTypeId}>
                                                        {transactionType.transactionTypeName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                
                                        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                                            {/* SPORT ID */}
                                            <select
                                                required={true}
                                                name="sportId"
                                                id="sportId"
                                                className="select w-full py-[0.4em] text-sm"
                                                value={createRegionTaxValues.sportId}
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, sportId: parseInt(e.target.value) });
                                                }}
                                            >
                                                <option value="">--Select Sport--</option>
                                                {sports.map((sport) => (
                                                    <option key={sport.sportId} value={sport.sportId}>{sport.sportName}</option>
                                                ))}
                                            </select>
                                        </div>
                
                                        <div className=" sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2 ">
                                            {/* TAX RATE PERCENTAGE */}
                                            <input
                                                required={true}
                                                placeholder="Tax Rate %"
                                                id="taxRatePercentage"
                                                name="taxRatePercentage"
                                                type="number"
                                                className="input py-1 text-sm w-full"
                                                value={createRegionTaxValues.taxRatePercentage === 0 ? "" : createRegionTaxValues.taxRatePercentage}
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, taxRatePercentage: parseInt(e.target.value) });
                                                }}
                                            />
                                        </div>
                
                                        <div className=" sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2 ">
                                            {/* TAX TYPE CODE */}
                                            <input
                                                required={true}
                                                placeholder="Tax Type Code"
                                                id="taxTypeCode"
                                                name="taxTypeCode"
                                                type="text"
                                                className="input py-1 text-sm w-full"
                                                value={createRegionTaxValues.taxTypeCode}
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, taxTypeCode: e.target.value });
                                                }}
                                            />
                                        </div>
                
                                        <div className="sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2 ">
                                            {/* TAX FORMULA */}
                                            <input
                                                required={true}
                                                placeholder="Tax Formula"
                                                id="taxFormula"
                                                name="taxFormula"
                                                type="text"
                                                className="input py-1 text-sm w-full"
                                                value={createRegionTaxValues.taxFormula}
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, taxFormula: e.target.value });
                                                }}
                                            />
                                        </div>
                                        <div className="sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2 flex flex-row items-center justify-items-center justify-center">
                                            {/* IS ACTIVE */}
                                            <label htmlFor="isActive" className="text-nowrap">Is Active</label>
                                            <input
                
                                                name="isActive"
                                                id="isActive"
                                                checked={createRegionTaxValues.isActive}
                                                type="checkbox"
                                                className="py-1  mx-4"
                                                onChange={(e) => {
                                                    setRegionTaxValues({ ...createRegionTaxValues, isActive: e.target.checked ? true: false });
                                                }}
                                            />
                                        </div>
                
                                        <div className="sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                                            <button
                                                disabled={!createRegionTaxFormValid}
                                                type="submit"
                                                className="btn primary w-full"
                                            >
                                                Create
                                            </button>
                                        </div>
                
                                    </form>
                                )}


                                {/* MARK: REGION TAXES TABLE */}  
                                <div className="mt-8 mx-4">
                                    <table className="table w-full border">
                                        <thead>
                                            <tr>
                                                <th className="border">Sport</th>
                                                <th className="border">Transaction Type</th>
                                                <th className="border">Tax Rate %</th>
                                                <th className="border">Tax Type Code</th>
                                                <th className="border">Tax Formula</th>
                                                <th className="border">Is Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {regionTaxes && regionTaxes.map((regionTax, index) => {
                                                const sportName = sports.find((s) => s.sportId === regionTax.sportId)?.sportName;
                                                const transactionTypeName = transactionTypes.find((s) => s.transactionTypeId === regionTax.transactionTypeId)?.transactionTypeName;
                                                return (
                                                    <tr key={`region-tax-${index}`}>
                                                        <td className="text-center border">{sportName}</td>
                                                        <td className="text-center border">{transactionTypeName}</td>
                                                        <td className="text-center border">{regionTax.taxRatePercentage}%</td>
                                                        <td className="text-center border">{regionTax.taxTypeCode}</td>
                                                        <td className="text-center border">{regionTax.taxFormula}</td>
                                                        <td className="text-center border">{regionTax.isActive ? "Yes" : "No"}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </Modal>
            )}
            <ToastContainer></ToastContainer>

        </div>
    )
}


export default RegionPage