import React, { useState } from 'react'
import { ControlService } from '../services/ControlService'
import { ResponseService } from '../services/ResponseService'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserRequest } from '../Request';
import { userResponse } from '../types';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await ControlService.login(username, password)
      .then(async (response) => {
        if (response.responseCode === 200) {
          ResponseService.successMessage('Successful')
          localStorage.setItem('token', response.responseMessage)

          const req : getUserRequest = {
            searchString: username,
            tenantId: null,
            userId: null
          }

          await ControlService.GetUsers<userResponse[]>(req, response.responseMessage)
            .then((res) => {
              console.log(res)
              localStorage.setItem('userID', res[0].userId.toString())
              localStorage.setItem('tenantID', res[0].tenantId.toString())
              navigate('/fieldsheet')
            })
        } else {
          ResponseService.failMessage(response.responseMessage)
        }
      })
      .catch((error) => {ResponseService.failMessage('Login failed. Please try again.')})
  }

  const handleForgotPass = () => {
    navigate('/forgotpassword')
  }

  return (
    <div className='flex min-h-screen items-center justify-center bg-blue-100 font-inter'>
      <div className='w-full max-w-md px-8 py-4 bg-white rounded-lg shadow-md'>
        <h1 className='text-2xl font-bold text-center mb-4'>Login</h1>
        <form
          onSubmit={handleSubmit}
          className='space-y-4'
        >
          <div className='flex flex-col'>
            <label
              htmlFor='username'
              className='text-sm font-medium mb-1'
            >
              Username:
            </label>
            <input
              type='text'
              id='username'
              name='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className='appearance-none rounded-md relative block w-full px-3 py-2 border border-blue-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
          </div>
          <div className='flex flex-col'>
            <label
              htmlFor='password'
              className='text-sm font-medium mb-1'
            >
              Password:
            </label>
            <input
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className='appearance-none rounded-md relative block w-full px-3 py-2 border border-blue-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
          </div>
          <button
            type='submit'
            className='w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-500 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            Login
          </button>

          <div className='flex justify-end'>
            <label className='underline underline-offset-1 hover:cursor-pointer' onClick={handleForgotPass}>
              Forgot Password
            </label>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  )
}

export default LoginPage
