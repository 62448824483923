import { createContext, ReactNode, useState } from 'react';
import { AppState } from '.././types'; 

interface ContextProps {
  appStateStore: AppState;
  setAppStateStore: React.Dispatch<React.SetStateAction<AppState>>;
}

const AppContext = createContext<ContextProps>({
  appStateStore:{},
  setAppStateStore: () => {}
});

interface ContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [appStateStore, setAppStateStore] =  useState<AppState>({});

  return (
    <AppContext.Provider value={{ appStateStore, setAppStateStore }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider, createContext};