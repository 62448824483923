export interface HorseRacingData {
  meetingName: string;
  races: {
    raceNumber: number;
    horses: string[];
  }[];
}

export interface EventData {
  category: string;
  tournaments: {
    name: string;
    events: string[];
  }[];
}

export interface AccordionItem {
  category: string;
  tournaments: {
    name: string;
    isOpen: boolean;
    events: string[];
  }[];
}

export enum Page {
  Operator,
  Fieldsheet,
  Report,
  Users,
  Region,
  Roles,
  ForgotPassword,
  UnauthAccess
}

export interface Sport {
  sportId: number,
  sportName: string,
  iconCss: string
}

export interface Category {
  categoryId: number;
  categoryName: string;
  countryCode: string;
  isOpen: boolean;
  tournaments: Tournament[];
  uniqueDates: MapUniqueDates[]
}

export interface MapUniqueDates {
  dateId: number,
  tournamentDate: Date,
  isOpen: boolean,
}
export interface Tournament {
  tournamentId: number,
  dataProviderId: number,
  tournamentName: string,
  tournamentDate: string,
  categoryId: number
  sportId: number,
  isOpen: boolean,
  events: Event[],
}

export interface Event {
  eventId: number,
  dataProviderId: number,
  eventName: string,
  eventDate: string,
  eventNumber: number,
  tournamentId: number,
  categoryId: number,
  sportId: number,
  eventStatusId: number,
  liability: number | null
}

// Bet Type Responses
export interface BetType {
  betTypeId: number,
  sportId: number,
  betTypeName: string,
  ruleset: string
}

export interface BetTypeSpecific {
  betTypeSpecificId: number,
  betTypeId: number,
  betTypeSpecificName: string
}

export interface Market {
  eventId: number,
  betTypeId: number,
  specifiers: number,
  marketNumber: number,
  dataProviderId: number,
  marketName: string,
  currentOdd: number,
  previousOdd: number,
  isFavourite: boolean,
  marketStatusId: number,
  betTypeSpecificId: number,
}

// Profit & Loss

export interface eventProfitResponse {
  betTypeSpecificId: number,
  betTypeId: number,
  betTypeSpecificName: string
}

export interface marketProfitResponse {
  betTypeSpecificId: number,
  betTypeId: number,
  betTypeSpecificName: string
}

export interface betTypeProfitResponse {
  betTypeSpecificId: number,
  betTypeId: number,
  betTypeSpecificName: string
}

// Users
export interface userResponse {
  userId: number,
  tenantId: number,
  locationId: number,
  roleId: number,
  accountStatusId: number,
  gender: string,
  userName: string,
  emailAddress: string,
  phoneNumber: string,
  password: string,
  pin: string,
  firstName: string,
  otherNames: string,
  lastName: string,
  dateOfBirth: Date,
  otpToken: string,
  identityCode: string,
  identityNumber: string
}

export enum Gender {
  Male, Female, Other
}


export interface EditUserSettings {
  userId: number,
  userName: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string
}

export interface Location {
  locationId: number,
  tenantId: number,
  regionId: number,
  countryId: number,
  locationName: string,
  locationDescription: string,
  email1: string,
  email2: string,
  phone1: string,
  phone2: string,
  addressLine1: string,
  addressLine2: string,
}

export interface RoleRes {
  roleId: number,
  regionId: number,
  tenantId: number,
  roleName: string,
  roleDescription: string,
  permissionIds: string
}

export interface AccountStatus {
  accountStatusId: number,
  accountStatusName: string,
  accountStatusDescription: string
}

export interface ForgotPassword {
  responseCode: number,
  responseMessage: string,
  relatedId: number
}

//Status
export interface MarketStatusRes {
  marketStatusId: number,
  marketStatusName: string
}

export interface EventStatusRes {
  eventStatusId: number,
  eventStatusName: string
}

export interface EventBetTypesRes {
  eventId: number,
  betTypeId: number,
  betTypeSpecificId: number,
  dataProviderId: number,
  placePercentage: number,
  placesPaid: number,
  description: string,
  eventGroupId: number,
  eventStatusId: number,
  betTypeName: string,
  betTypeSpecificName: string,
  eventGroupName: string,
  liability: number | null
}

export interface ProfitLossRes {
  tenantId: number,
  dataProviderId: number,
  eventId: number,
  betTypeId: number,
  betTypeSpecificId: number,
  marketNumber: number,
  totalProfitLoss: number,
  totalStake: number,
  totalWin: number
}

export interface ReportListRes {
  reportName: string,
  reportUrl: string
}


// Tenant

export interface TenantRes {
  tenantId: number,
  locationId: number,
  tenantShortName: string,
  tenantDescription: string
}

export interface CreateTenantRes {
  responseCode: number,
  responseMessage: string,
  relatedId: number
}

export interface TenantSettingsTypes {
  settingTypeId: number,
  settingTypeName: string
}

export interface TenantSettings {
  settingId: number,
  regionId: number,
  tenantId: number,
  settingTypeId: number,
  settingNumericValue: number,
  settingStringValue: string
}

export interface AddTenantSettingsRes {
  responseCode: number,
  responseMessage: string,
  relatedId: number
}

//MARK: Region
export interface RegionRes {
  regionId: number,
  countryId: number,
  regionName: string
}

export interface RegionTaxRes {
  regionId: number,
  transactionTypeId: number,
  sportId: number,
  dataProviderId: number,
  taxRatePercentage: number,
  taxTypeCode: string,
  taxFormula: string,
  isActive: boolean
}

export interface TransactionTypeRes {
  transactionTypeId: number,
  transactionTypeName: string,
  transactionTypeDescription: string,
  affectsCashBalance: boolean,
  affectsBonusBalance: boolean,
  affectsFreeBetBalance: boolean,
  affectsLoyaltyPointBalance: boolean,
  affectsStakeBalance: boolean
}

export interface CountryRes {
  countryId: number,
  countryName: string,
  officialStateName: string,
  alpha2Code: string,
  alpha3Code: string,
  numericCode: number,
  internetCode: string,
  isoCode: string,
}

export interface BaseRes {
  responseCode: number,
  responseMessage: string,
  relatedId: number
}

//MARK: Permission
export interface PermissonRes {
  permissionId: number,
  permissionName: string,
  permissionDescription: string,
  resource: string
}

//MARK: Liability
export interface LiabilityRes {
  tenantId: number,
  dataProviderId: number,
  eventId: number,
  betTypeId: number,
  betTypeSpecificId: number,
  liabilityAmount: number | null
}

//MARK AppStateStore

export interface AppState {
  currentProfitLossList?: ProfitLossRes[],
  currentProfitLoss?: ProfitLossRes,
  currentMarketList?: Market[],
  currentBetTypes?:BetType[],
  currentEvent?:Event;
}
