import React, { useEffect, useRef, useState } from 'react'
import { PermissonRes, RegionRes, RoleRes, TenantRes } from '../types'
import { ControlService } from '../services/ControlService';
import { ResponseService } from '../services/ResponseService';
import { GetTenant, PostRole, RoleReq } from '../Request';
import { ToastContainer } from 'react-toastify';

const RolesPage: React.FC = () => {

    const token = localStorage.getItem('token');

    //MARK: DEFAULT VALUES
    const defaultRoleRequest: RoleReq = {}
    const defaultTenantRequest: GetTenant = { tenantId: null }

    const defaultRoleFormValues: PostRole = {
        roleId: 0,
        regionId: 0,
        tenantId: -1,
        roleName: '',
        roleDescription: '',
        permissionIds: '',
    };

    const [selectedRole, setSelectedRole] = useState<number>(-1);

    //defaults placed so that this can be extended later if need be
    const roleRequest = { ...defaultRoleRequest };
    const tennantRequest = { ...defaultTenantRequest };

    //MARK: PAGE STATE
    const [permissions, setPermissions] = useState<PermissonRes[]>([]);
    const [roles, setRoles] = useState<RoleRes[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    //role form value
    const [roleFormValues, setRoleFormValues] = useState<PostRole>({ ...defaultRoleFormValues });

    //MARK: ROLE LINKED DATA
    const [tenants, setTenants] = useState<TenantRes[]>([]);
    const [regions, setRegions] = useState<RegionRes[]>([]);

    //MARK: REQUESTS
    async function getPermissions() {
        const res = await ControlService.GetPermissions<PermissonRes[]>(token);
        if (res) {
            setPermissions(res);
        } else {
            ResponseService.failMessage("Unable to get permissions");
        }
    }

    async function getRoles() {
        const res = await ControlService.GetRoles<RoleRes[]>(roleRequest, token);
        if (res) {
            setRoles(res);
        } else {
            ResponseService.failMessage("Unable to get roles");
        }
    }

    async function getTenants() {
        const res = await ControlService.GetTenant<TenantRes[]>(tennantRequest, token);
        if (res) {
            setTenants(res);
        } else {
            ResponseService.failMessage("Unable to get tenants");
        }
    }

    async function getRegions() {
        const res = await ControlService.GetRegions<RegionRes[]>(token);
        if (res) {
            setRegions(res);
        } else {
            ResponseService.failMessage("Unable to get regions");
        }
    }

    useEffect(() => {
        getPermissions();
        getRoles();
        getRegions();
        getTenants();
    }, []);

    /* trigger rerenders based on when any of these stateful values change
    Due to the way in which react works, where state updates are pushed
    to a sort of queue, this will force rerenders. */
    useEffect(() => {
    }, [roleFormValues, selectedRole, selectedPermissions])

    function handlePermissionCheckboxChange(permissonId: number) {
        if (selectedPermissions.includes(permissonId)) {
            setSelectedPermissions([...selectedPermissions.filter((id) => id !== permissonId)]);
        } else {
            setSelectedPermissions([...selectedPermissions, permissonId]);
        }
    }

    function handleSelectClearAllClicked() {
        if (selectedPermissions.length < permissions.length) {
            setSelectedPermissions(permissions.map((permisson) => permisson.permissionId));
        } else {
            setSelectedPermissions([]);
        }
    }

    //posting function
    async function postRole(data: PostRole) {
        const res = await ControlService.PostRole<PostRole>(data, token);
        if (res) {
            ResponseService.successMessage("Role updated");
            getRoles();
            setSelectedPermissions([]);
            setRoleFormValues({ ...defaultRoleFormValues });
            setSelectedRole(res.roleId);
            setIsCreateRoleFormValid(true);

        } else {
            ResponseService.failMessage("Unable to create role");
        }
    }
    
    //MARK: FORM CODE
    const createRoleForm = useRef<HTMLFormElement>(null);
    const [isCreateRoleFormValid, setIsCreateRoleFormValid] = useState(false);

    function validateCreateRoleForm() {
        if (createRoleForm.current) {
            return createRoleForm.current.checkValidity();
        }else{
            return false;
        }
    }

    function handleCreateRoleFormChange() {
        setIsCreateRoleFormValid(validateCreateRoleForm());
    }


    //on form submit
    async function handleRoleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const selectedString = `|${[...selectedPermissions].sort((a, b) => a - b).join("|")}|`;

        //console.log(selectedString);

        if (selectedRole === -1) {
            //CREATE
            if (validateCreateRoleForm()) {
                /* console.log("Create");
                console.table({ ...roleFormValues, roleId: roles.length + 1, permissionIds: selectedString }); */
                await postRole({ ...roleFormValues, roleId: roles.length + 1, permissionIds: selectedString });
            }else{
                ResponseService.failMessage("Please fill out all required fields");
            }
        } else {
            //UPDATE
            if (validateCreateRoleForm()) {
                /* console.log("Update");
                console.table({ ...roleFormValues, roleId: selectedRole, permissionIds: selectedString }); */
                await postRole({ ...roleFormValues, roleId: selectedRole, permissionIds: selectedString });
            }else{
                ResponseService.failMessage("Please fill out all required fields");
            }
        }

    }

    //Handle changing the selected role
    function onSelectedRoleChange(roleId: number) {
        //console.log("HIT", roleId);
        if (roleId === -1) {
            setRoleFormValues({ ...defaultRoleFormValues });
            setSelectedPermissions([]);
        } else {
            let role;

            if (roleId === 1) {
                role = roles.find((role) => role.roleId === roleId && role.roleName !== "Default");
            } else {
                role = roles.find((role) => role.roleId === roleId);
            }

            if (role) {
                setRoleFormValues(role);
                const permissionIds = role.permissionIds.substring(1, role.permissionIds.length - 1).split("|").map((id) => parseInt(id));
                setSelectedPermissions(permissionIds);
            }
        }
    }

    //MARK: JSX RETURN
    return (
        <div className="container mx-auto p-2">
            {/* MARK: ROLE FORM */}
            <form onSubmit={handleRoleFormSubmit} ref={createRoleForm} onChange={handleCreateRoleFormChange} className="border">
                
                <div className="flex w-full">
                    {/* SELECTED ROLE */}
                    <div className="flex w-full bg-blue-200 py-2 px-4 items-center gap-2">
                        <label htmlFor="selectedRole" className='text-lg'>
                            Select a Role: 
                        </label>
                        <select
                            name="selectedRole"
                            id="selectedRole"
                            className="select py-[0.4em] flex-auto"
                            defaultValue={-1}
                            onChange={(e) => {
                                setSelectedRole(parseInt(e.target.value));
                                onSelectedRoleChange(parseInt(e.target.value));

                            }}
                        >
                            {[...roles.sort((a, b) => a.roleId - b.roleId)].map((role) => {
                                if (role.roleId === 1 && role.roleName === "Default") {
                                    return <option key={'default'} disabled value={999}>{role.roleName}</option>
                                }

                                if (role.roleId === 1) {
                                    return <option key={role.roleId} disabled value={role.roleId}>{role.roleId} | {role.roleName}</option>
                                }

                                return <option key={role.roleId} value={role.roleId}>{role.roleId} | {role.roleName}</option>
                            })}
                            <option value="-1">--Create New Role--</option>
                        </select>
                    </div>
                </div>     

                <div className="bg-blue-100">
                    <h1 className="text-lg p-4">Role Options</h1>
                </div>

                {/* MARK: ROLE INFO */}
                <div className="grid grid-cols-5 gap-4 bg-blue-100 p-4 ">
                    
                    {/* ROLE NAME */}
                    <div className="flex flex-col">
                        <label htmlFor="roleName" className='font-bold'>
                            Role Name
                        </label>
                        <input
                            id="roleName"
                            placeholder="Role Name"
                            required={true}
                            className="input py-1 text-sm"
                            type="text"
                            value={roleFormValues.roleName}
                            onChange={(e) => setRoleFormValues({ ...roleFormValues, roleName: e.target.value })}
                        />
                    </div>

                    {/* DESCRIPTION */}
                    <div className="flex flex-col">
                        <label htmlFor="roleDescription" className='font-bold'>
                            Role Description
                        </label>
                        <input
                            id="roleDescription"
                            placeholder="Role Description"
                            required={true}
                            className="input py-1 text-sm"
                            type="text"
                            value={roleFormValues.roleDescription}
                            onChange={(e) => setRoleFormValues({ ...roleFormValues, roleDescription: e.target.value })}
                        />
                    </div>

                    {/* REGION */}
                    <div className="flex flex-col">
                        <label htmlFor="regionId" className='font-bold'>
                            Region
                        </label>
                        <select
                            required={true}
                            name="regionId"
                            id="regionId"
                            className="select py-[0.4em] text-sm"
                            value={roleFormValues.regionId}
                            onChange={(e) => setRoleFormValues({ ...roleFormValues, regionId: Number(e.target.value) })}
                        >
                            <option value="">--Select Region--</option>
                            {regions.map((region) => (
                                <option key={`region-${region.regionId}`} value={region.regionId}>{region.regionName}</option>
                            ))}
                        </select>
                    </div>

                    {/* TENANT */}
                    <div className="flex flex-col">
                        <label htmlFor="tenantId" className='font-bold'>Tenant</label>
                        <select
                            required={true}
                            name="tenantId"
                            id="tenantId"
                            className="select py-[0.4em] text-sm"
                            value={roleFormValues.tenantId}
                            onChange={(e) => setRoleFormValues({ ...roleFormValues, tenantId: Number(e.target.value) })}
                        >
                            <option value="">--Select Tenant--</option>
                            {tenants.map((tenant) => (
                                <option key={`tenant-${tenant.tenantId}`} value={tenant.tenantId}>{tenant.tenantShortName}</option>
                            ))}
                        </select>
                    </div>

                    {/* TOGGLE PERMISSIONS BUTTON */}
                    <div className="flex flex-col">
                        <label htmlFor="togglePermissions" className='font-bold'>Toggle Permissions</label>
                        <button className="btn outline-b text-sm py-1 w-full right-0 h-8"
                            id="togglePermissions"
                            name="togglePermissions"
                            onClick={(e) => {e.preventDefault(); handleSelectClearAllClicked()}}
                        >
                            {selectedPermissions.length < permissions.length ? "Select All" : "Clear All"}
                        </button>
                    </div>
                </div>

                <div
                    className="grid grid-cols-5 gap-4 mt-4 px-4"
                >
                    {permissions.map((permisson, index) => (
                        //MARK: CHECKBOXES
                        /* Checkboxes */
                        <div
                            key={`permission-${permisson.permissionId}`}
                            className="flex mx-2">
                            <input
                                type="checkbox"
                                name="permission"
                                checked={selectedPermissions.includes(permisson.permissionId)}
                                onChange={() => { handlePermissionCheckboxChange(permisson.permissionId) }} />
                            <p className="capitalize mx-2">{permisson.permissionDescription}</p>
                        </div>
                    ))}

                </div>
                <button
                    disabled={isCreateRoleFormValid ? false: true}
                    type="submit"
                    className="btn primary py-1 w-2/12 h-8 m-4"
                >
                    {selectedRole === -1 ? "Create" : "Update"}
                </button>
            </form>

            <ToastContainer></ToastContainer>

        </div>
    )
}

export default RolesPage