import axios from 'axios';
import { CreateTenant as PostTenant, GetRegionTaxReq, GetTenant, GetTenantSettings, PostRole, RegionTaxReq, RoleReq, createLocationReq, createUserRequest, forgotPasswordRequest, getLocation, getRole, getUserRequest, postUserRequest } from '../Request';
import { RegionRes, TenantSettings, userResponse } from '../types';

const baseUrl = process.env.REACT_APP_BASE_ADMIN;
const subURL = process.env.REACT_APP_SUB_CONTROL;

export class ControlService {
  // MAIN CALLS HERE
  static async login(username: string, password: string){
    try {
      const response = await axios.post(`${baseUrl}${subURL}/login`, {
        username: username,
        password: password
      })

      return response.data;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  static async register<T>(obj: postUserRequest){
    try{
      const response = await axios.post<T>(`${baseUrl}${subURL}/register`, {
        gender: obj.gender,
        userName: obj.userName,
        email: obj.email,
        phone: obj.phone,
        password: obj.password,
        pin: obj.pin,
        firstName: obj.firstName,
        otherNames: obj.otherNames,
        lastName: obj.lastName,
        dateOfBirth: obj.dateOfBirth,
        identityCode: obj.identityCode,
        identityNumber: obj.identityNumber,
      })
      return response.data;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  static async createUser<T>(obj: createUserRequest, token: string | null){
    const data = {
        userId: obj.userId,
        tenantId: obj.tenantId,
        locationId: obj.locationId,
        roleId: obj.roleId,
        accountStatusId: obj.accountStatusId,
        gender: obj.gender,
        userName: obj.userName,
        emailAddress: obj.emailAddress,
        phoneNumber: obj.phoneNumber,
        password: obj.password,
        pin: obj.pin,
        firstName: obj.firstName,
        otherNames: obj.otherNames,
        lastName: obj.lastName,
        dateOfBirth: obj.dateOfBirth,
        otpToken: obj.otpToken,
        identityCode: obj.identityCode,
        identityNumber: obj.identityNumber
    }

    const config = {
      headers: { 
      'accept': 'text/plain',
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}` 
      } 
    }

    try{
      const response = await axios.post<T>(`${baseUrl}${subURL}/user`, data, config)
      return response.data;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  static async updateUser<T>(obj: userResponse, token: string | null){
    const data = {
      userId: obj.userId,
      tenantId: obj.tenantId,
      locationId: obj.locationId,
      roleId: obj.roleId,
      accountStatusId: obj.accountStatusId,
      userName: obj.userName,
      emailAddress: obj.emailAddress,
      phoneNumber: obj.phoneNumber,
      firstName: obj.firstName,
      lastName: obj.lastName,
      identityNumber: obj.identityNumber ? obj.identityNumber : "",
      identityCode: obj.identityCode ? obj.identityCode : "",
    }

    const config = {
      headers: { 
      'accept': 'text/plain',
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}` 
      } 
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/user`, data, config)
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async forgotPassword<T>(obj: forgotPasswordRequest, token: string | null){
    const data = {
      recoveryMethod: obj.recoveryMethod,
      recoveryEmailOrPhone: obj.recoveryEmailOrPhone
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/forgotpassword`, data, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // Get Users
  static async GetUsers<T>(params: getUserRequest, token: string | null) {
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1){
      urlParams = '';
    }

    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/user${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // Location
  static async GetLocation<T>(params: getLocation, token: string | null) {
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1){
      urlParams = '';
    }

    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/location${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  

  // Location
  static async GetRole<T>(params: getRole, token: string | null) {
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1){
      urlParams = '';
    }

    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/role${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  

  // Account Status
  static async GetAccountStatus<T>(token: string | null) {
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/accountstatus`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  // Tenant 
  static async GetTenant<T>(params: GetTenant, token: string | null){    
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1){
      urlParams = '';
    }

    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/tenant${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  static async PostTenant<T>(params: PostTenant, token: string | null){   
    var obj = {
      locationId: params.locationId,
      tenantDescription: params.tenantDescription,
      tenantId: params.tenantId,
      tenantShortName: params.tenantShortName
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/tenant`, obj, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async TenantSettings<T> (params: GetTenantSettings, token: string | null) {
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1){
      urlParams = '';
    }

    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/setting${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async AddTenantSettings<T> (params: TenantSettings, token: string | null) {
    const obj = {
      tenantId: params.tenantId,
      settingNumericValue: params.settingNumericValue,
      settingStringValue: params.settingStringValue,
      settingTypeId: params.settingTypeId,
      regionId: params.regionId,
      settingId: params.settingId
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/setting`, obj, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async TenantSettingsType<T>(token: string | null){
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/settingtype`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async GetRegions<T>(token: string | null){
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/region`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async CreateLocation<BaseRes> (params: createLocationReq, token: string | null) {
    const obj = {
      addressLine1: params.addressLine1,
      addressLine2: params.addressLine2,
      countryId: params.countryId,
      email1: params.email1,
      email2: params.email2,
      locationDescription: params.locationDescription,
      locationId: params.locationId,
      locationName: params.locationName,
      phone1: params.phone1,
      phone2: params.phone2,
      regionId: params.regionId,
      tenantId: params.tenantId
    }

    try {
      const response = await axios.post<BaseRes>(`${baseUrl}${subURL}/location`, obj, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async GetCountries<T>(token: string | null){
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/country`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Creates a new region with the provided data.
   *
   * @param {RegionRes} object - The region data to be created.
   * @param {string | null} token - The authentication token.
   * @return {Promise<T>} The created region data.
   */
  static async PostRegion<T>(object: RegionRes, token?: string | null){
    const data = {
      regionId: object.regionId,
      regionName: object.regionName,
      countryId: object.countryId
    }

    const config = {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/region`, data, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Retrieves the region tax data based on the provided parameters.
   *
   * @param {GetRegionTaxReq} params - The parameters for the region tax request.
   * @param {string | null} token - The authentication token (optional).
   * @return {Promise<T>} The region tax data.
   */
  static async GetRegionTax<T>(params: GetRegionTaxReq, token?: string | null){
    const url = new URL(`${baseUrl}${subURL}/regiontax`);
    
    //convert object to url params
    for(const key in params){
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key as keyof GetRegionTaxReq]
        if(value !== null){
          url.searchParams.append(key, value?.toString() || '');
        }
      }
    }

    const config = {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    const promises = [axios.get<Array<T>>(url.href.toString(), config)]
    
    //if isActive is blank, create a separate request to get inactive ones
    if (!params.isActive) {
      url.searchParams.append('isActive', 'false');
      promises.push(axios.get<Array<T>>(url.href.toString(), config));
    }

    try {
      const responses = await Promise.all(promises);

      const fullResponse = responses.reduce((acc, response) => {
        return [...acc, ...response.data];
      }, [] as T[]);

      console.log(fullResponse);

      return fullResponse;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  static async GetTransactionTypes<T>(token?: string | null){
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/transactiontype`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async CreateRegionTax<T>(obj: RegionTaxReq, token?: string | null){

    const config = {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/regiontax`, obj, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Retrieves permissions from the server.
   *
   * @param {string | null} token - The authentication token.
   * @return {Promise<T>} The permissions data.
   */
  static async GetPermissions<T>(token?: string | null){
    try {
      const response = await axios.get<T>(`${baseUrl}${subURL}/permission`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

   /**
   * Retrieves permissions from the server.
   *
   * @param {string | null} token - The authentication token.  * 
   * @param {string | null} resourceName - The resourceName.
   * @return {Promise<T>} The permissions data.
   */
   static async GetPermissionList<T>(resourceName: string, token?: string | null){
    try {
      const response = await axios.get<any>(`${baseUrl}${subURL}/permission?resource=${resourceName}`, { headers: { Authorization: `Bearer ${token}` } });
      const permissionNames: string[] = response.data.map((permission: { permissionName: any; }) => permission.permissionName);
      return permissionNames;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async GetRoles<T>(params: RoleReq, token?: string | null){
    const url = new URL(`${baseUrl}${subURL}/role`);
    
    //convert object to url params
    for(const key in params){
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key as keyof RoleReq]
        if(value !== null){
          url.searchParams.append(key, value?.toString() || '');
        }
      }
    }

    try {
      const response = await axios.get<T>(url.href, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async PostRole<T>(obj: PostRole, token?: string | null){
    const config = {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    try {
      const response = await axios.post<T>(`${baseUrl}${subURL}/role`, obj, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}