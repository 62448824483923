import React, { useEffect, useRef, useState } from 'react'

const AccessPage: React.FC = () => {

    //MARK: JSX RETURN
    return (
        <div className="container mx-auto p-2">
            {/* MARK: ROLE FORM */}
                <div className="bg-blue-100">
                    <h1 className="text-lg p-4">Unauthorized Access</h1>
                </div>
        </div>
    )
}

export default AccessPage