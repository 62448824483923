import React from "react";
import { ReportListRes } from "../types";

interface ReportNavComponentProps {
  onReportClick: (report: ReportListRes) => void;
  ReportListProp: ReportListRes[];
  SelectedReport: ReportListRes;
}

const ReportNavComponent: React.FC<ReportNavComponentProps> = ({
  onReportClick,
  ReportListProp,
  SelectedReport
}) => {
  const handleClick = (report: ReportListRes) => {
    onReportClick(report);
  };

  return (
    <>
    <div className="p-2">
      {ReportListProp.length > 0 ? ReportListProp.map((report, index) => (
        <div 
            key={index} 
            onClick={() => handleClick(report)}
            className={`underline hover:bg-blue-200 cursor-pointer p-2 ${SelectedReport?.reportName === report.reportName ? "bg-blue-100" : ""}`}
        >
          {report.reportName}
        </div>
      )) : (
        <div>No data</div>
      )}
      </div>
    </>
  );
};

export default ReportNavComponent;
