import React, { useState, useEffect, useRef } from "react";
import { userResponse, Gender, Location, RoleRes, AccountStatus, EditUserSettings } from "../types";
import { getLocation, getRole, getUserRequest, postUserRequest,createUserRequest } from "../Request";
import { ResponseService } from "../services/ResponseService";
import { ControlService } from "../services/ControlService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Users: React.FC = () => {
  const token = localStorage.getItem('token');
  const [users, setUsers] = useState<userResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [userRequest, setUserRequest] = useState<getUserRequest>({
    userId: null,
    tenantId: null,
    searchString: null,
  });

  //user object values, default vals put into form
  const [postUserRequest, setPostUserRequest] = useState<postUserRequest>({
    gender: '',
    userName: '',
    email: '',
    phone: '',
    password: '',
    pin: '',
    firstName: '',
    otherNames: '',
    lastName: '',
    dateOfBirth: '',
    identityCode: '',
    identityNumber: '',
  });

  
  const [createUserRequest, setCreateUserRequest] = useState<createUserRequest>({
    userId: 0,
    tenantId: 0,
    locationId: null,
    roleId: null,
    accountStatusId: null,
    gender: null,
    userName: null,
    emailAddress: null,
    phoneNumber: null,
    password: null,
    pin: null,
    firstName: null,
    otherNames: null,
    lastName: null,
    dateOfBirth: null,
    otpToken: null,
    identityCode: null,
    identityNumber: null,
  });

  //MARK: PAGE STATE
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);

  const [locationRequest, setLocationRequest] = useState<getLocation>({locationId: null, regionId: null, tenantId: null});
  const [location, setLocation] = useState<Location[]>();

  const [roleRequest, setRoleRequest] = useState<getRole>({roleId: null, tenantId: null});
  const [role, setRole] = useState<RoleRes[]>();

  const [accountStatus, setAccountStatus] = useState<AccountStatus[]>();

  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    fetchUsers();
    getLocation();
    getRole();
    getAccountStatus();
    
    setLoading(false);
  }, []);

  const fetchUsers = async () => {
    await ControlService.GetUsers<userResponse[]>(userRequest, token)
      .then((res) => {
        res.sort((a, b) => {
          const aa = a.userId;
          const bb = b.userId;
          return aa - bb;
        });

        setUsers(res);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        ResponseService.failMessage("Error fetching users");
      });
  };

  //MARK: CREATE USER FORM
  //runs on form submit
  //Does a final validation check on the form before submission
  async function formSubmit(event : React.FormEvent<HTMLFormElement>){
    event.preventDefault();
    
    //get the validity of the form
    const form  = event.currentTarget as HTMLFormElement;
    const valid = form.checkValidity();

    if (valid) {
      await createUser();
    }else{
      ResponseService.failMessage('Invalid Input'); 
    }
  }

  //runs whenever a value in the form changes
  function onFormChange(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();

    //onChange (on a form) returns the element that changed.
    //in THIS CASE SPECIFICALLY we know that it's two parents up
    const form = event.target.parentElement?.parentElement as HTMLFormElement;

    //check the validity of the form
    const validation = form.checkValidity();
    
    //set the validation
    if (validation === true) {
      setFormValid(true);
    }else{
      setFormValid(false);
    }
    
  }

  const createUser = async () => {
    try {
      await ControlService.register<userResponse[]>(postUserRequest)

      fetchUsers();
    } catch (error) {
      console.error("Error creating user:", error);
      ResponseService.failMessage("Error creating user");
    }
  };

  const getLocation = async () => {
    await ControlService.GetLocation<Location[]>(locationRequest, token)
    .then((res) => setLocation(res))
    .catch((error) => ResponseService.failMessage('Failed to retrieve locations'))
  }

  const getRole = async () => {
    await ControlService.GetRole<RoleRes[]>(roleRequest, token)
    .then((res) => setRole(res))
    .catch((error) => ResponseService.failMessage('Failed to retrieve roles'))
  }

  const getAccountStatus = async () => {
    await ControlService.GetAccountStatus<AccountStatus[]>(token)
    .then((res) => setAccountStatus(res))
    .catch((error) => ResponseService.failMessage('Failed to retrieve account status'))
  }


  //MARK: EDIT USER FORM
  //form validity state for edit user form
  const [editUserFormValid, setEditUserFormValid] = useState<boolean | null>(null);

  //ref to the edit user form
  const editUserFormRef = useRef<HTMLFormElement>(null);

  //values of the edit user form, for submission
  const [editUserFormValues, setEditUserFormValues] = useState<userResponse>()

  //returns whether the edit user form is valid
  function validateEditUserForm():boolean{
    const validity = editUserFormRef.current?.checkValidity();
    if(validity === true){
      return true;
    }else{
      return false;
    }
  }

  //runs whenever a value assigned to the editUserForm changes
  function onEditUserFormFieldChange(){
    const validity = validateEditUserForm();

    if(validity === true){
      setEditUserFormValid(true);
    }else{
      setEditUserFormValid(false);
    }
  }

  
  //on edit user form submit
  async function onEditUserFormSubmit(event : React.FormEvent<HTMLFormElement>){
    event.preventDefault();
    console.log('submit hit');
    
    if (validateEditUserForm() && editUserFormValues) {
      //console.table(editUserFormValues);
      
      //make post here
      await ControlService.updateUser<userResponse>(editUserFormValues, token);

      //set currently selected user to undefined to close the edit form
      setEditUserFormValues(undefined);
      setEditUserFormValid(null);

      //refetch users
      fetchUsers();

    }else{
      ResponseService.failMessage('Invalid Input');
    }
  }


  //MARK: JSX RETURN
  return (
    <>
      <div className="container mx-auto p-2">
        <div className="bg-blue-200">
          <button
            className="w-full text-left text-lg px-4 py-2 border-y border-blue-200 flex justify-between items-center"
            onClick={() => setToggleCreate(!toggleCreate)}
          >
            <span>Create User</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 transform transition-transform duration-300 ${
                toggleCreate ? "rotate-180" : ""
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {toggleCreate && (
            /*MARK: CREATE USER FORM*/
            <form 
              onSubmit={formSubmit} 
              onChange={onFormChange}
              className="flex flex-wrap items-center justify-end bg-blue-100 border border-blue-300">
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                {/* USERNAME */}
                <input
                  required={true}
                  id="username"
                  name="username"
                  autoComplete="off"
                  type="email"
                  placeholder="Username"
                  className="input w-full py-1 text-sm"
                  defaultValue={postUserRequest.userName as string}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPostUserRequest({
                        ...postUserRequest,
                        userName: e.target.value,
                      })
                    }
                  }
                />
              </div>

              {/* FIRST NAME */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required={true}
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      firstName: e.target.value,
                    })
                  }
                />
              </div>

              {/* OTHER NAMES */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required={false}
                  id="otherNames"
                  name="otherNames"
                  type="text"
                  placeholder="Other Names"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      otherNames: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* LAST NAME */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required={true}
                  autoComplete="off"
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="input py-1 w-full text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      lastName: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* GENDER */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <select
                  required={true}
                  id="gender"
                  name="gender"
                  defaultValue={postUserRequest.gender as string}
                  className="border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1 invalid:border-red-500 invalid:bg-red-100"
                  onChange={(e : React.ChangeEvent<HTMLSelectElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      gender: e.target.value,
                    })
                  }
                >
                  <option value="" disabled selected>
                    --Gender--
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>

              {/* ID NUMBER */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required
                  id="identityNumber"
                  name="identityNumber"
                  type="text"
                  placeholder="ID Number"
                  minLength={13}
                  maxLength={13}
                  className="input py-1 w-full text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      identityNumber: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* ID CODE */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  id="identityCode"
                  name="identityCode"
                  type="text"
                  placeholder="ID Code"
                  className="input py-1 w-full text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      identityCode: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* EMAIL */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required
                  id="email"
                  name="email"
                  type="email"
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  placeholder="Email"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      email: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* PHONE NUMBER */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required
                  id="phone"
                  name="phone"
                  autoComplete="off"
                  type="tel"
                  pattern="^\+?\d{10,}$"
                  placeholder="Phone Number"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      phone: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* DATE*/}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  className="input w-full py-1 text-sm"
                  onChange={(e : React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      dateOfBirth: new Date(e.target.value).toISOString().split("T")[0],
                    })
                  }
                />
              </div>

              {/* PASSWORD */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  required
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  type="password"
                  placeholder="Password"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      password: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* PIN */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                <input
                  id="pin"
                  name="pin"
                  autoComplete="off"
                  type="text"
                  placeholder="Pin"
                  className="input w-full py-1 text-sm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPostUserRequest({
                      ...postUserRequest,
                      pin: e.target.value,
                    })
                  }
                ></input>
              </div>

              {/* CREATE USER BUTTON */}
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2 flex">
                <button
                  disabled={formValid ? false : true}
                  type="submit"
                  className={`btn primary w-full`}
                >
                  Create User
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      {/* DIVIDER */}
      <div className="container mx-auto p-2">
        <hr className="border-t-2 border-blue-300" />
      </div>

      {/* MARK: FILTER */}
      <div className="container mx-auto p-2 overflow-x-auto">
        <div className="bg-blue-200">
          <button
            className="w-full text-left text-lg px-4 py-2 border-y border-blue-200 flex justify-between items-center"
            onClick={() => setToggleFilter(!toggleFilter)}
          >
            <span>Filter</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 transform transition-transform duration-300 ${
                toggleFilter ? "rotate-180" : ""
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {toggleFilter && (
            <div className="flex flex-wrap items-center align-center justify-center bg-blue-100 border border-blue-300">
                {/* USER ID */}
                <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                    <input
                        type="text"
                        placeholder="User ID"
                        className="border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1"
                        onChange={(e: any) =>
                            setUserRequest({
                                ...userRequest,
                                userId: (e.target.value !== '' ? parseInt(e.target.value) : null),
                            })
                        }
                    ></input>
                </div>
                
                {/* TENANT ID */}
                <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                    <input
                        type="text"
                        placeholder="Tenant ID"
                        className="border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1"
                        onChange={(e: any) =>
                            setUserRequest({
                                ...userRequest,
                                tenantId: (e.target.value !== '' ? parseInt(e.target.value) : null),
                            })
                        }
                    ></input>
                </div>
                
                {/* USERNAME */}
                <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                    <input
                        type="text"
                        placeholder="Username"
                        className="border border-blue-300 rounded-md w-full focus:outline-none hover:ring-2 hover:ring-blue-500 text-center text-sm py-1"
                        onChange={(e: any) =>
                            setUserRequest({
                                ...userRequest,
                                searchString: e.target.value,
                            })
                        }
                    ></input>
                </div>

                <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 my-2">
                    <button
                        disabled
                        onClick={fetchUsers}
                        className="border border-blue-500 rounded-md w-full bg-blue-500 hover:bg-blue-700 text-white text-center text-sm py-1"
                    >
                        Search
                    </button>
                </div>
            </div>
          )}
        </div>

        {/* MARK: USERS TABLE */}
        <form id="editUserForm" onSubmit={onEditUserFormSubmit} ref={editUserFormRef}/>
        <table className="min-w-full text-center">
          <thead>
            <tr>
              <th className="border px-4 py-2 w-2">User ID</th>
              <th className="border px-4 py-2 ">Username</th>
              <th className="border px-4 py-2 ">First Name</th>
              <th className="border px-4 py-2 ">Surname</th>
              <th className="border px-4 py-2 ">Email Address</th>
              <th className="border px-4 py-2 ">Phone Number</th>
              <th className="border px-4 py-2 ">Role</th>
              <th className="border px-4 py-2 ">Status</th>
              <th className="border px-4 py-2 w-40">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            ) : (
              users.map((user) => {
                const status = accountStatus?.filter(x=>x.accountStatusId === user.accountStatusId)[0] 
                const r = role?.filter(x => x.roleId === user.roleId)[0]

                return (
                
                <React.Fragment key={user.userId}>
                  {/* If the edit user id value !== the id of the user in the row */}
                  {editUserFormValues?.userId !== user.userId ?
                    //MARK: VIEW USER ROW
                    (
                      <tr className="hover:bg-blue-100">
                        <td className="border px-4 py-2">{user.userId}</td>
                        <td className="border px-4 py-2">{user.userName}</td>
                        <td className="border px-4 py-2">{user.firstName}</td>
                        <td className="border px-4 py-2">{user.lastName}</td>
                        <td className="border px-4 py-2">{user.emailAddress}</td>
                        <td className="border px-4 py-2">{user.phoneNumber}</td>
                        <td className="border px-4 py-2">{r?.roleName}</td>
                        <td className="border px-4 py-2">{status?.accountStatusName}</td>
                        <td className="border px-4 py-2">
                          <button
                            type="button"
                            className="btn outline-b w-3/4"
                            onClick={(e) => {
                              //for some reason this attempts to submit the form, so just prevent default
                              e.preventDefault();
                              //set the user to be edited
                              setEditUserFormValues(previous => {return user});
                              //set the form validity to null so that subsequent edits don't cause the save button to enable
                              setEditUserFormValid(null);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ) 
                    : 
                    //MARK: EDIT USER ROW
                    (
                      <tr role="form" className="hover:bg-blue-200">
                        {/* USER ID */}
                        <td className="border py-2">{user.userId}</td>
                        {/* USERNAME */}
                        <td className="border py-2">
                          <input
                            /* 
                              NB: Form attribute used to avoid browser warning, 
                              form element is above the parent table so scroll up or ctrl+f editUserForm
                            */
                            form="editUserForm"
                            required
                            pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                            type="email" 
                            id="userName"
                            name="userName"
                            autoComplete="off"
                            className="input" 
                            placeholder={user.userName}
                            defaultValue={user.userName}
                            onChange={(e) => {
                                editUserFormValues &&
                                setEditUserFormValues({
                                  ...editUserFormValues,
                                  userName: e.target.value
                                })
                                onEditUserFormFieldChange(); 
                              }
                            }
                          />
                        </td>
                        {/* FIRST NAME */}
                        <td className="border py-2">
                          <input
                            form="editUserForm" 
                            required
                            type="text"
                            id="firstName"
                            name="firstName"
                            autoComplete="off"
                            className="input" 
                            placeholder={user.firstName}
                            defaultValue={user.firstName}
                            onChange={(e) => { editUserFormValues && 
                                setEditUserFormValues({ 
                                  ...editUserFormValues, 
                                  firstName: e.target.value 
                                });

                                onEditUserFormFieldChange();
                              }
                            }
                          />
                        </td>
                        {/* LAST NAME */}
                        <td className="border py-2">
                          <input
                            required
                            form="editUserForm"
                            type="text"
                            id="lastName"
                            name="lastName"
                            autoComplete="off"
                            className="input" 
                            placeholder={user.lastName}
                            defaultValue={user.lastName}
                            onChange={(e) => { editUserFormValues &&
                                setEditUserFormValues({ 
                                  ...editUserFormValues, 
                                  lastName: e.target.value 
                                });
                                onEditUserFormFieldChange();
                            }}
                          />
                        </td>
                        {/* EMAIL ADDRESS */}
                        <td className="border py-2">
                          <input
                            required
                            form="editUserForm"
                            type="email"
                            id="emailAddress"
                            name="emailAddress"
                            autoComplete="off"
                            className="input" 
                            placeholder={user.emailAddress}
                            defaultValue={user.emailAddress}
                            onChange={(e) => {
                              if (editUserFormValues) {
                                setEditUserFormValues({
                                  ...editUserFormValues,
                                  emailAddress: e.target.value
                                });
                                onEditUserFormFieldChange();
                              }
                            }}
                          />
                        </td>
                        {/* PHONE NUMBER */}
                        <td className="border py-2">
                          <input 
                            required
                            form="editUserForm"
                            type="text"
                            pattern="^\+?\d{10,}$"
                            id="phoneNumber"
                            name="phoneNumber"
                            autoComplete="off"
                            className="input" 
                            placeholder={user.phoneNumber}
                            defaultValue={user.phoneNumber}
                            onChange={(e) => {
                              if (editUserFormValues) {
                                setEditUserFormValues({
                                  ...editUserFormValues,
                                  phoneNumber: e.target.value
                                });
                                onEditUserFormFieldChange();
                              }
                            }}
                          />
                        </td>
                        <td className="border py-2">{r?.roleName}</td>
                        <td className="border py-2">{status?.accountStatusName}</td>
                        <td className="border py-2 flex gap-2 mx-1">
                          <button
                            form="editUserForm"
                            disabled={editUserFormValid ? false : true}
                            type="submit"
                            className="btn outline-b w-1/2"
                          >
                            Save
                          </button>
                          <button
                            className="btn outline-b w-1/2"
                            onClick={() => setEditUserFormValues(undefined)}
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                      
                    )
                  }
                </React.Fragment>                
              )})
            )}
          </tbody>
        </table>
      </div>

      <ToastContainer></ToastContainer>
    </>
  );
};

export default Users;
