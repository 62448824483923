import React, { useState } from "react";
import { ControlService } from "../services/ControlService";
import { ResponseService } from "../services/ResponseService";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPasswordRequest } from "../Request";
import { ForgotPassword } from "../types";

const ForgotPasswordPage: React.FC = () => {
  const token = localStorage.getItem('token')
  const [forgotPass, setForgotPass] = useState<forgotPasswordRequest>({
    recoveryEmailOrPhone: "",
    recoveryMethod: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await ControlService.forgotPassword<ForgotPassword>(
        forgotPass, token
      );

      if (response.responseCode === 200) {
        ResponseService.successMessage("Reset Link Sent");
      } else {
        ResponseService.failMessage(response.responseMessage);
      }
    } catch (error) {
      ResponseService.failMessage("Login failed. Please try again.");
    }
  };

  const handleLogin = () => {
    navigate('/login');
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-blue-100 font-inter">
      <div className="w-full max-w-md px-8 py-4 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-center mb-4">Forgot Password</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="w-full px-2 my-2 flex">
            <select
              id="accStatusId"
              className="border border-blue-300 rounded-md w-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 text-center cursor-pointer"
              onChange={(e) =>
                setForgotPass({ ...forgotPass, recoveryMethod: e.target.value })
              }
            >
              <option value="" disabled selected>
                --Select Recovery Method--
              </option>
              <option value="Email">Email</option>
              <option value="Phone">Phone</option>
            </select>
          </div>

          <div className="w-full px-2 my-2">
            <input
              type="text"
              placeholder="eop"
              className="border border-blue-300 rounded-md w-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
              onChange={(e) =>
                setForgotPass({
                  ...forgotPass,
                  recoveryEmailOrPhone: e.target.value,
                })
              }
            ></input>
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-500 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Password
          </button>

          <div className="flex justify-end">
            <label 
                className="underline underline-offset-1 hover:cursor-pointer"
                onClick={handleLogin}>
              Login
            </label>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordPage;
