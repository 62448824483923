import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_ADMIN;
const subURL = process.env.REACT_APP_SUB_CONTROL;

export class AdminApiService {
  static async getOperatorData(operatorId: string, token: string | null) {
    try {
      const response = await axios.get(`${baseUrl}/api/operators/${operatorId}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error; 
    }
  }

  // Add other API methods as needed for the OperatorPage component
  static async ReportList<T>(token: string | null){
    try{
      const response = await axios.get<T>(`${baseUrl}${subURL}/ReportList`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }
}