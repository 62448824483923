import React, { useCallback, useEffect, useState, useContext } from "react";
import { Sport, Category, Tournament, Event, MapUniqueDates } from "../types";
import { AppContext } from ".././services/ContextProvider";
import {
  eventRequest,
  tournamentRequest,
  categoryRequest,
  sportRequest,
} from "../Request";
import { EventService } from "../services/EventService";

interface updateEventRequest {
  cat: Category | null | undefined,
  tourn: Tournament | null | undefined,
  eid: number | null | undefined
}


interface EventNavComponentProps {
  onEventChange: (
    event: Event,
    category: Category,
    tournament: Tournament
  ) => void;
  initialLoad: boolean;
  onUpdateInitialLoad: (iLoad: boolean) => void;
  handleUpdateEvent: updateEventRequest;
  updateEventIDFromChild: (data: updateEventRequest) => void;
}

const EventNavComponent: React.FC<EventNavComponentProps> = ({
  onEventChange,
  initialLoad,
  onUpdateInitialLoad,
  handleUpdateEvent,
  updateEventIDFromChild
}) => {
  const token = localStorage.getItem('token');

  //SET REQUESTS
  const [sportReq, setSportReq] = useState<sportRequest>({
    dataProviderId: 1,
    sportId: null,
    startDate: null,
    endDate: null,
    eventStatusId: null,
  });
  const [categoryReq, setCategoryReq] = useState<categoryRequest>({
    dataProviderId: 1,
    sportId: null,
    categoryId: null,
    startDate: new Date(Date.now()).toISOString().split("T")[0],
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0], // Default end date to 7 days from now
    eventStatusId: null,
    pageNo: 1,
    pageSize: 100,
  });
  const [tournamentReq, setTournamentReq] = useState<tournamentRequest>({
    dataProviderId: 1,
    sportId: null,
    categoryId: null,
    tournamentId: null,
    startDate: new Date(Date.now()).toISOString().split("T")[0],
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0], // Default end date to 7 days from now
    eventStatusId: null,
    pageNo: 1,
    pageSize: 100,
  });
  const [eventReq, setEventReq] = useState<eventRequest>({
    dataProviderId: 1,
    sportId: null,
    categoryId: null,
    tournamentId: null,
    startDate: new Date(Date.now()).toISOString().split("T")[0],
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0], // Default end date to 7 days from now
    eventStatusId: null,
    eventId: null,
  });

  //SET RESPONSES
  const [sports, setSports] = useState<Sport[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategory, setFilteredCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(true); // State to track the open/close state of the header
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true); // State to track the open/close state of the filter
  const mainContentHeight = isFilterOpen ? "58vh" : "85vh";
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const { appStateStore, setAppStateStore } = useContext(AppContext);

  // Tailwind variables
  const nav_links =
    "flex flex-row justify-left items-center text-[2vh] w-full p-1 cursor-pointer hover:text-blue-500 hover:underline";
  const nav_links_category = "";
  const nav_links_dates = "pl-2";
  const nav_links_tournament = "pl-3";
  const nav_links_event = "pl-8";
  const nav_links_selected = "text-blue-500";

  // Tailwind Variables
  // Fiter
  const filter_search_input =
    "border border-blue-300 rounded-l-md w-80 focus:outline-none";
  const filter_search_button =
    "border border-blue-500 rounded-r-md w-full bg-blue-500 hover:bg-blue-700 text-white text-center py-1";
  const filter_dropdown =
    "border border-blue-300 rounded-md w-full focus:outline-none text-center p-1 cursor-pointer";
  const filter_calendar =
    "border border-blue-300 rounded-md w-full focus:outline-none text-center px-1  cursor-pointer";
  const filter_button =
    "border border-blue-500 rounded-md w-full bg-blue-500 hover:bg-blue-700 text-white text-center";

  useEffect(() => {
    fetchCategories(sportReq, categoryReq, tournamentReq, eventReq)
      .then((data) => {
        // Check if initialLoad is true and categoriesData has at least one category
        if (initialLoad && data.length > 0) {
          initialEvent(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [initialLoad]);

  useEffect(() => {
    if(handleUpdateEvent && handleUpdateEvent.eid && handleUpdateEvent.tourn && handleUpdateEvent.cat && handleUpdateEvent.eid !== 0){
      fetchUpdatedEvent(handleUpdateEvent.eid, handleUpdateEvent.tourn, handleUpdateEvent.cat);

      let updateReq = handleUpdateEvent;
      updateReq.eid = 0;

      updateEventIDFromChild(updateReq);
    }
  }, [handleUpdateEvent])

  // API Calls
  const fetchCategories = async (
    sportReq: sportRequest,
    categoryReq: categoryRequest,
    tournamentReq: tournamentRequest,
    eventReq: eventRequest
  ) => {
    try {
      const sportData: Sport[] = await EventService.GetSport<Sport[]>(sportReq, token);
      setSports([...sportData]);

      const categoryPromises = sportData.map(async (sport) => {
        const categoryReqCopy = { ...categoryReq, sportId: sport.sportId };
        const categoriesData: Category[] = await EventService.GetCategory<
          Category[]
        >(categoryReqCopy, token);

        const categoriesWithTournamentsPromises = categoriesData.map(
          async (category) => {
            const tournamentReqCopy = {
              ...tournamentReq,
              categoryId: category.categoryId,
            };
            const tournamentsData: Tournament[] = await fetchTournaments(
              tournamentReqCopy
            );

            const filterDates: MapUniqueDates[] =
              FilterUniqueDates(tournamentsData);

            // Map tournaments back into the category
            const categoryWithTournaments = {
              ...category,
              tournaments: tournamentsData,
              uniqueDates: filterDates,
            };

            return categoryWithTournaments;
          }
        );

        const categoriesWithTournaments = await Promise.all(
          categoriesWithTournamentsPromises
        );

        return categoriesWithTournaments;
      });

      const categoriesWithSportData = await Promise.all(categoryPromises);
      setCategories(categoriesWithSportData.flat());
      setFilteredCategories(categoriesWithSportData.flat());
      return categoriesWithSportData.flat();
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return an empty array in case of error
    }
  };

  const FilterUniqueDates = (tournaments: Tournament[]) => {
    const tempArray: MapUniqueDates[] = [];
    const uniqueDatesSet = new Set<string>();

    tournaments.forEach((tournament) => {
      // Extract date from tournamentDate string
      const date = new Date(tournament.tournamentDate);
      // Ensure date only (without time)
      date.setHours(0, 0, 0, 0);
      // Convert date to string for Set comparison
      const dateString = date.toISOString();
      // Check if date is already in the set before adding
      if (!uniqueDatesSet.has(dateString)) {
        uniqueDatesSet.add(dateString);
      }
    });

    let count = 0;

    uniqueDatesSet.forEach((d) => {
      tempArray.push({
        dateId: count,
        tournamentDate: new Date(d),
        isOpen: false,
      });

      count++;
    });

    // Set the unique dates state
    return tempArray;
  };

  const fetchUpdatedEvent = async(eid: number, tourn: Tournament, cat: Category) => {
    let req = eventReq;
    req.eventId = eid;
    req.tournamentId = tourn.tournamentId;
    req.categoryId = cat.categoryId;

    await EventService.GetEvent<Event[]>(req, token)
      .then((res) => {
        onRaceChange(res[0], cat, tourn);
      })
  }

  const initialEvent = async (fil: Category[]) => {
    const cat1 = fil[0].categoryId;
    const tourn1 = fil[0].tournaments[0].tournamentId;

    await fetchEvents(cat1, tourn1)
      .then((data) => {
        onEventChange(data[0], fil[0], fil[0].tournaments[0]);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
    updateInitialLoad(false);
  };

  const updateInitialLoad = (value: boolean) => {
    onUpdateInitialLoad(value);
  };

  // Fetch Tournaments for a specific category
  const fetchTournaments = async (
    tournamentReq: tournamentRequest
  ): Promise<Tournament[]> => {
    try {
      const tournamentsData: Tournament[] = await EventService.GetTournament<
        Tournament[]
      >(tournamentReq, token);

      tournamentsData.sort((a, b) => {
        const dateA = new Date(a.tournamentDate).getTime();
        const dateB = new Date(b.tournamentDate).getTime();
        return dateA - dateB;
      });

      return tournamentsData;
    } catch (error) {
      console.error("Error fetching tournaments:", error);
      return [];
    }
  };

  // Toggle Category Open/Close
  const toggleCategoryOpen = (categoryId?: number) => {
    setFilteredCategories((categories) =>
      categories.map((category) =>
        category.categoryId === categoryId
          ? { ...category, isOpen: !category.isOpen }
          : { ...category, isOpen: false }
      )
    );
  };

  const toggleDateOpen = (categoryId: number, i: number) => {
    setFilteredCategories((categories) =>
      categories.map((category) =>
        category.categoryId === categoryId
          ? {
              ...category,
              uniqueDates: category.uniqueDates.map((date) =>
                date.dateId === i
                  ? { ...date, isOpen: !date.isOpen }
                  : { ...date, isOpen: false }
              ),
            }
          : category
      )
    );
  };

  // Toggle Tournament Open/Close
  const toggleTournamentOpen = async (
    categoryId: number,
    tournamentId: number
  ) => {
    // Map over categories and await the result of each promise
    const updatedCategories = await Promise.all(
      filteredCategory.map(async (category) => {
        if (category.categoryId === categoryId) {
          // If the category matches, update the tournament and return the updated category
          const updatedTournaments = category.tournaments.map(
            async (tournament) => {
              if (
                tournament.tournamentId === tournamentId &&
                !tournament.events
              ) {
                // If the tournament matches and events are not fetched yet, fetch its events
                const eventsData: Event[] = await fetchEvents(
                  categoryId,
                  tournamentId
                );
                return { ...tournament, events: eventsData, isOpen: true };
              } else if (tournament.tournamentId === tournamentId) {
                return { ...tournament, isOpen: !tournament.isOpen };
              } else {
                return { ...tournament, isOpen: false };
              }
            }
          );
          return {
            ...category,
            tournaments: await Promise.all(updatedTournaments),
          };
        }
        return category; // Return unchanged category if not matching
      })
    );
    // Update the state with the resolved categories
    setFilteredCategories(updatedCategories);
  };

  // Fetch Events for a specific tournament
  const fetchEvents = async (
    categoryId: number,
    tournamentId: number
  ): Promise<Event[]> => {
    try {
      const eventReqCopy = {
        ...eventReq,
        categoryId,
        tournamentId,
      };
      const eventData: Event[] = await EventService.GetEvent<Event[]>(
        eventReqCopy, token
      );

      // Sort events by eventDate in ascending order
      eventData.sort((a, b) => {
        const dateA = new Date(a.eventDate).getTime();
        const dateB = new Date(b.eventDate).getTime();
        return dateA - dateB;
      });

      return eventData;
    } catch (error) {
      console.error("Error fetching events:", error);
      return [];
    }
  };

  // OnClick Of Race
  const onRaceChange = (
    event: Event,
    category: Category,
    tournament: Tournament
  ) => {
    onEventChange(event, category, tournament);
  };

  // Handle search
  const handleSearch = () => {
    const filteredCategories = categories
      .filter((category) => {
        // Check if the category name matches the search text or any tournament name matches the search text
        const categoryMatch = category.categoryName
          .toLowerCase()
          .includes(searchText.toLowerCase());

        const tournamentMatch = category.tournaments.some((tournament) =>
          tournament.tournamentName
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        return categoryMatch || tournamentMatch;
      })
      .map((category) => {
        // If the category matches, keep all tournaments
        if (
          category.categoryName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return category;
        }

        // Filter tournaments within the category based on the search text
        const filteredTournaments = category.tournaments.filter((tournament) =>
          tournament.tournamentName
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        return {
          ...category,
          tournaments: filteredTournaments,
        };
      })
      .filter((category) => category.tournaments.length > 0); // Remove categories with no matching tournaments
    setFilteredCategories(filteredCategories);
  };

  // Function to handle changes in the start date dropdown
  const handleStartDate = (value: string) => {
    setCategoryReq({
      ...categoryReq,
      startDate: value || null,
    });
    setTournamentReq({
      ...tournamentReq,
      startDate: value || null,
    });
    setEventReq({
      ...eventReq,
      startDate: value || null,
    });
  };

  // Function to handle changes in the end date dropdown
  const handleEndDate = (value: string) => {
    setCategoryReq({
      ...categoryReq,
      endDate: value || null,
    });
    setTournamentReq({
      ...tournamentReq,
      endDate: value || null,
    });
    setEventReq({
      ...eventReq,
      endDate: value || null,
    });
  };

  // Function to handle changes in the status dropdown
  const handleStatus = (value: string) => {
    setCategoryReq({
      ...categoryReq,
      eventStatusId: parseInt(value) || null,
    });
    setTournamentReq({
      ...tournamentReq,
      eventStatusId: parseInt(value) || null,
    });
    setEventReq({
      ...eventReq,
      eventStatusId: parseInt(value) || null,
    });
  };

  // Function to handle changes in the sport dropdown
  const handleSport = (value: string) => {
    setSportReq({
      ...sportReq,
      sportId: parseInt(value) || null,
    });
  };

  // Handle filter
  const handleFilter = async () => {
    await fetchCategories(sportReq, categoryReq, tournamentReq, eventReq);
  };

  const formatDate = (datetimeString: any) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  const onEventClick = (
    event: Event,
    category: Category,
    tournament: Tournament
  ) => {
    onRaceChange(event, category, tournament);
    setSelectedEventId(event.eventId); // Set the selected event ID
    setAppStateStore((prevAppState) => ({
        ...prevAppState,
        currentEvent: event,
    }));
    }

  const svgImage = (open: boolean) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-3 w-3 transform transition-transform duration-300 ${
          open ? "" : "-rotate-90"
        }`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  return (
    <>
      <div
        className={`overflow-y-auto`}
        style={{ maxHeight: mainContentHeight }}
      >
        {/* NAVBAR HEADER */}
        <div className="border-b border-blue-500">
          <div className="w-full flex p-1">
            <div className="lg:w-full w-4/5 ">
              <h2
                className="text-lg font-bold cursor-pointer"
                onClick={() => {setIsHeaderOpen(!isHeaderOpen)}}
              >
                {sports.map((sport) => sport.sportName)}
              </h2>
            </div>
          </div>
        </div>

        {/* NAVBAR CONTENT */}
        {isHeaderOpen && // Render categories only if the header is open
          (filteredCategory.length === 0 ? (
            <div className="flex justify-center items-center h-1/4 text-[2vh]">
              No data
            </div>
          ) : (
            <div className="p-1">
              {filteredCategory.map((category) => (
                <div
                  key={category.categoryId}
                  className="border-b border-blue-200"
                >
                  <h3
                    className={`${nav_links} ${nav_links_category} ${
                      category.isOpen ? nav_links_selected : ""
                    }`}
                    onClick={() => toggleCategoryOpen(category.categoryId)}
                  >
                    {svgImage(category.isOpen)}
                    {category.categoryName}
                  </h3>

                  {category.isOpen && (
                    <>
                      {/* Render uniqueDates */}
                      {category.uniqueDates.map((uniqueDate) => (
                        <div key={uniqueDate.tournamentDate.getTime()}>
                          <div
                            className={`${nav_links} ${nav_links_dates} ${
                              uniqueDate.isOpen ? nav_links_selected : ""
                            }`}
                            onClick={() => {
                              toggleDateOpen(
                                category.categoryId,
                                uniqueDate.dateId
                              );
                            }}
                          >
                            {svgImage(uniqueDate.isOpen)}
                            {formatDate(uniqueDate.tournamentDate)}
                          </div>

                          {/* Render tournaments that fall within uniqueDate */}
                          {uniqueDate.isOpen &&
                            category.tournaments.map(
                              (tournament) =>
                                new Date(tournament.tournamentDate)
                                  .toDateString()
                                  .split("T")[0] ===
                                  new Date(uniqueDate.tournamentDate)
                                    .toDateString()
                                    .split("T")[0] && (
                                  <div key={tournament.tournamentId}>
                                    <div
                                      className={`${nav_links} ${nav_links_tournament} ${
                                        tournament.isOpen
                                          ? nav_links_selected
                                          : ""
                                      }`}
                                      onClick={async () =>
                                        await toggleTournamentOpen(
                                          category.categoryId,
                                          tournament.tournamentId
                                        )
                                      }
                                    >
                                      {svgImage(tournament.isOpen)}
                                      {tournament.tournamentName}
                                    </div>
                                    {tournament.isOpen &&
                                    tournament.events.length > 0
                                      ? tournament.events.map((event) => (
                                          <div
                                            key={event.eventId}
                                            className={`${nav_links} ${nav_links_event} ${
                                              selectedEventId === event.eventId
                                                ? nav_links_selected
                                                : ""
                                            }`}
                                            onClick={() =>
                                              onEventClick(
                                                event,
                                                category,
                                                tournament
                                              )
                                            }
                                          >
                                            {event.eventName}
                                          </div>
                                        ))
                                      : tournament.isOpen && (
                                          <div
                                            className={`hover:bg-transparent hover:underline-0 ${nav_links} ${nav_links_event}`}
                                          >
                                            No Events
                                          </div>
                                        )}
                                  </div>
                                )
                            )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
      </div>

      {/* FILTER COMPONENT */}
      <div
        className={`bg-white w-full absolute left-0 right-0 bottom-0 p-2 transition-all h-20vh`}
      >
        <div>
          <button
            className="w-full text-left text-sm px-2 py-2 border-y border-blue-500 flex justify-between items-center"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <h4 className="text-lg font-semibold">Filter</h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 transform transition-transform duration-300 ${
                isFilterOpen ? "rotate-180" : ""
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4A.75.75 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isFilterOpen && (
            //MARK: FILTER OPTIONS
            <div>
              <div className="max-w-7xl my-2 ">
                {/* FILTER OPTIONS */}
                <div className="flex flex-wrap items-center justify-between ">
                  <div className="w-full flex items-center mb-2">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className={`${filter_search_input} text-center text-lg py-1`}
                    />
                    <button
                      type="button"
                      onClick={handleSearch}
                      className={`${filter_search_button} flex flex-row items-center justify-center max-w-10 text-lg py-2`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-4.35-4.35"
                        />
                        <circle cx="10" cy="10" r="7" />
                      </svg>
                    </button>
                  </div>

                  <div className="w-full flex items-center mb-2">
                    <div className="w-1/3">
                      <label className="mr-2 text-lg">Start Date:</label>
                    </div>
                    <div className="w-2/3">
                      <input
                        type="date"
                        value={categoryReq.startDate || ""}
                        onChange={(e) => handleStartDate(e.target.value)}
                        className={`${filter_calendar} text-lg`}
                      />
                    </div>
                  </div>
                  <div className="w-full flex items-center mb-2">
                    <div className="w-1/3">
                      <label className="mr-2 text-lg">End Date:</label>
                    </div>
                    <div className="w-2/3">
                      <input
                        type="date"
                        value={categoryReq.endDate || ""}
                        onChange={(e) => handleEndDate(e.target.value)}
                        className={`${filter_calendar} text-lg`}
                      />
                    </div>
                  </div>
                  <div className="w-full flex items-center mb-2">
                    <div className="w-1/3">
                      <label className="mr-2 text-lg">Sport:</label>
                    </div>
                    <div className="w-2/3">
                      <select
                        value={sportReq.sportId || ""}
                        onChange={(e) => handleSport(e.target.value)}
                        className={`${filter_dropdown} text-lg`}
                      >
                        <option value="">Select</option>
                        {sports.map((sport) => (
                          <option key={sport.sportId} value={sport.sportId}>
                            {sport.sportName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-full flex items-center mb-3 sm:mb-0">
                    <div className="w-1/3">
                      <label className="mr-2 text-lg">Status:</label>
                    </div>
                    <div className="w-2/3">
                      <select
                        value={categoryReq.eventStatusId || ""}
                        onChange={(e) => handleStatus(e.target.value)}
                        className={`${filter_dropdown} text-lg`}
                      >
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full flex items-center pt-2">
                    <button
                      type="button"
                      onClick={handleFilter}
                      className={`btn primary w-full text-lg mt-2`}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EventNavComponent;
