import React from 'react';
import { Page } from '../types';
import HorseIcon from './HorseIcon';

interface TopNavProps {
  onNavbarClick: (page: Page) => void;
  onLogout: () => void;
}

const TopNavComponent: React.FC<TopNavProps> = ({ onNavbarClick, onLogout }) => {
  const tenantID = localStorage.getItem('tenantID');

  const handleClick = (page: Page) => {
    onNavbarClick(page);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout();
  };

  return (
    <>
      {/* nav container */}
      <nav className="bg-blue-400 w-full sticky top-0 z-50 text-white h-16">
        {/* Flex for logo, nav items */}
        <div className="flex w-full h-full pl-16 items-center">
          {/* logo */}
          <div className="flex items-center">
            <HorseIcon svgStyles='w-12 h-12' />
            <h1 className="text-xl font-bold">Horse Racing</h1>
          </div>

          <div className="hidden md:flex flex-1 pl-16">
            {/* Nav Items */}
            <div className="flex items-center w-full">
              <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Fieldsheet)}>Fieldsheet</a>
              {
                tenantID && parseInt(tenantID) === 1 && (
                  <>
                    <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Operator)}>Tenants</a>
                    <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Users)}>Users</a>
                    <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Region)}>Regions</a>
                    <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Roles)}>Roles</a>
                    <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Report)}>Reports</a>
                  </>
                )
              }
              {/* Logout */}
              
              <div className="flex flex-1 justify-end mr-16">
                <a href="#" className="block hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={handleLogout}>Logout</a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* <nav className="bg-blue-400 w-full sticky top-0 z-50 text-white ">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 ml-9">
          <div className="flex items-center justify-between h-16" >
            <div className="flex items-center">

              
              <div className="flex items-center">
                <div className="h-full w-full">
                  <HorseIcon />
                </div>
                <div className="w-max">
                  <h1 className="bg-yellow-300">Horse Racing</h1>
                </div>
              </div>

              <div className="hidden md:block ">
                <div className="ml-10 flex items-baseline space-x-4 text-white">
                  <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Fieldsheet)}>Fieldsheet</a>
                  {
                    tenantID && parseInt(tenantID) === 1 && (
                      <>
                        <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Operator)}>Tenants</a>
                        <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Users)}>Users</a>
                        <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Region)}>Regions</a>
                        <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Roles)}>Roles</a>
                        <a href="#" className="hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={() => handleClick(Page.Report)}>Reports</a>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <a href="#" className=" hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm" onClick={handleLogout}>Logout</a>
              </div>
            </div>
          </div>
        </div>
      </nav> */}
    </>



  );
};

export default TopNavComponent;
