import axios from 'axios';
import { LiabilityReq, PostLiability, ProfitLossRequest } from '../Request';

const baseUrl = process.env.REACT_APP_BASE_WAGER;

const subURL = process.env.REACT_APP_SUB_WAGER;

export class WagerService {
  static async funtion_name_here(variable_here: any) {
    try {
      const response = await axios.get(`${baseUrl}/endpoint_here`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // Add other API methods as needed for the OperatorPage component

  static async ProfitLoss<ProfitLossRes>(params: ProfitLossRequest, token: string | null){
    let urlParams = '?';

    for(let [key, value] of Object.entries(params)){
      if(value !== null && value !== ''){
        urlParams += `${key}=${value}&`;
      }
    }

    if(urlParams.charAt(urlParams.length - 1) === '&'){
      urlParams = urlParams.slice(0, -1);
    }

    if(urlParams.length === 1) urlParams = '';

    try{  
      const response = await axios.get<ProfitLossRes>(`${baseUrl}${subURL}/profitandloss${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  static async GetLiabilities<T>(params: LiabilityReq, token: string | null) {
    const url = new URL(`${baseUrl}${subURL}/liability`);

    //convert object to url params
    for(const key in params){
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key as keyof LiabilityReq]
        if(value !== null){
          url.searchParams.append(key, value?.toString() || '');
        }
      }
    }

    //request headers
    const config = {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    //request
    try {
      const response = await axios.get<T>(url.href.toString(), config);
      return response.data;
    } catch (error) {
      console.error(error);
    }

  }

  static async PostLiability<T>(data: PostLiability, token: string | null) {
    try {
      const config = {
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }

      const response = await axios.post<T>(`${baseUrl}${subURL}/liability`, data, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}