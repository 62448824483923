import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavComponent from "../components/TopNavComponent";
import OperatorPage from "./TenantPage";
import FieldsheetPage from "./FieldsheetPage";
import ReportPage from "./ReportPage";
import { Page } from "../types";
import Users from "./Users";
import { useLocation } from "react-router-dom";
import RegionPage from "./RegionPage";
import RolesPage from "./RolesPage";
import AccessPage  from "./AccessPage";
import { ControlService } from '../services/ControlService'

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<Page>(Page.Fieldsheet);
  const [tokenAvailable, setTokenAvailable] = useState<boolean>(false);
  const token = localStorage.getItem('token');
  const location = useLocation();  
  const [allowedPages, setAllowedPages] = useState<string[]>([]); 

  useEffect(() => {
    initialLoad();  
    getPermissionList();
  }, []);

  async function getPermissionList() {
      const res = await ControlService.GetPermissionList<string[]>('menu', token);
      if (res) {
        setAllowedPages(res);
      } else { 
      }
  }

  useEffect(() => {
    // Check if token is available
    if (token !== null) {
      setTokenAvailable(true);
    } else {
      setTokenAvailable(false);
    }
  }, [token]);

  const initialLoad = () => {
    Object.keys(Page).forEach((key) => {
      const pagePath = key.toLowerCase();
      // const locPath =

      if (location.pathname === `/${pagePath}`) {
        setCurrentPage(Page[key as keyof typeof Page]);
      }
    });
  };

  const handleNavbarClick = (page: Page) => {        
    switch (page) {
      case Page.Operator:
        if(tokenAvailable){            
          if(!allowedPages.includes("Tenants"))  {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }           
          setCurrentPage(page);  
          navigate("/operator");
        }
        break;
      case Page.Fieldsheet:
        if(tokenAvailable){
          if(!allowedPages.includes("Fieldsheet")) {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }             
          setCurrentPage(page);  
          navigate("/fieldsheet");
        }
        break;
      case Page.Report:
        if(tokenAvailable){
          if(!allowedPages.includes("Reports"))  {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }              
          setCurrentPage(page);   
          navigate("/report");
        }
        break;
      case Page.Users:
        if(tokenAvailable){
          if(!allowedPages.includes("Users"))  {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }            
          setCurrentPage(page);     
          navigate("/users");
        }
        break;
      case Page.Region:
        if(tokenAvailable){
          if(!allowedPages.includes("Regions"))  {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }            
          setCurrentPage(page);    
          navigate("/regions");
        }
        break;
      case Page.Roles:
        if(tokenAvailable){
          if(!allowedPages.includes("Roles")) {
            setCurrentPage(Page.UnauthAccess);
            break;  
          }         
          setCurrentPage(page);   
          navigate("/roles");
        }
        break;
      default:           
        break;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="font-inter relative">
      <TopNavComponent
        onNavbarClick={handleNavbarClick}
        onLogout={handleLogout}
      />
      <div>
        {currentPage === Page.Operator && tokenAvailable && <OperatorPage />}
        {currentPage === Page.Fieldsheet && tokenAvailable && <FieldsheetPage />}
        {currentPage === Page.Report && tokenAvailable && <ReportPage />}
        {currentPage === Page.Users && tokenAvailable && <Users />}
        {currentPage === Page.Region && tokenAvailable && <RegionPage/>}
        {currentPage === Page.Roles && tokenAvailable && <RolesPage/>}
        {currentPage === Page.UnauthAccess && <AccessPage/>}
      </div>
    </div>
  );
};

export default Main;
